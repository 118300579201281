import * as React from 'react'
import PropTypes from 'prop-types'

const SvgCatalogo = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.367 1.146a.47.47 0 0 0-.436-.044L7.545 4.108.661 1.004a.47.47 0 0 0-.604.2.461.461 0 0 0-.057.223v11.997a.461.461 0 0 0 .297.433l7.07 2.733a.47.47 0 0 0 .336.002l7.569-2.818a.472.472 0 0 0 .304-.437V1.533a.466.466 0 0 0-.207-.387h-.002Zm-.728 1.08v10.798L8.003 15.49V4.926l6.636-2.7ZM7.069 4.912V15.48L.934 13.106V2.148l6.133 2.765Z"
      fill="currentColor"
    />
  </svg>
)

SvgCatalogo.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgCatalogo
