import * as React from 'react'
import PropTypes from 'prop-types'

const SvgUsuarios = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.67.962H3.326a3.343 3.343 0 0 0-2.351.97A3.3 3.3 0 0 0 0 4.269v9.08a3.3 3.3 0 0 0 .976 2.336c.623.62 1.47.969 2.352.97h15.341c.883 0 1.73-.349 2.354-.969A3.3 3.3 0 0 0 22 13.348V4.27a3.3 3.3 0 0 0-.977-2.338 3.343 3.343 0 0 0-2.354-.97ZM5.676 15.564a8.864 8.864 0 0 1 5.321-1.773c1.92 0 3.79.623 5.322 1.773H5.677Zm15.22-2.214a2.213 2.213 0 0 1-.654 1.564 2.242 2.242 0 0 1-1.574.65h-.536c-.017-.02-.024-.045-.042-.064A10.071 10.071 0 0 0 11 12.587 10.071 10.071 0 0 0 3.91 15.5a.643.643 0 0 0-.043.064h-.54a2.242 2.242 0 0 1-1.574-.65 2.213 2.213 0 0 1-.655-1.564V4.27a2.213 2.213 0 0 1 .655-1.565 2.242 2.242 0 0 1 1.574-.65h15.341c.59.002 1.156.235 1.574.65.418.415.653.977.654 1.564v9.08Z"
      fill="currentColor"
    />
    <path
      d="M11 3.17c-.865-.001-1.71.254-2.43.73a4.35 4.35 0 0 0-1.61 1.95 4.318 4.318 0 0 0 .948 4.735 4.397 4.397 0 0 0 4.765.942 4.366 4.366 0 0 0 1.961-1.601c.48-.715.737-1.554.737-2.414a4.334 4.334 0 0 0-1.282-3.07A4.39 4.39 0 0 0 11 3.17Zm0 7.49a3.181 3.181 0 0 1-1.76-.53 3.152 3.152 0 0 1-1.166-1.412 3.13 3.13 0 0 1 .686-3.43 3.186 3.186 0 0 1 3.452-.683 3.164 3.164 0 0 1 1.421 1.159 3.133 3.133 0 0 1-.394 3.974c-.594.59-1.399.922-2.239.923Z"
      fill="currentColor"
    />
  </svg>
)

SvgUsuarios.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgUsuarios
