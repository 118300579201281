import React, { useEffect } from 'react'
import { Form } from 'antd'
import { CustomInput, CustomFormItem } from 'common/molecules'
import Styles from './styles.module.scss'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { useParams } from 'react-router-dom'

export const UserForm = () => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data } = useSaleDetail(id || '', appId || '')
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ fullName: `${form.getFieldValue('customer').firstName} ${form.getFieldValue('customer').lastName}` })
    form.setFieldsValue({ buyEmail: form.getFieldValue('customer').email })
    form.setFieldsValue({ userEmail: form.getFieldValue('customer').registrationEmail })
    form.setFieldsValue({ giftEmail: form.getFieldValue('gift').email })
  }, [data])

  return (
    <div>
      <Form form={form} layout="vertical" className={Styles.form} initialValues={data}>

        <CustomFormItem name="fullName" label="Nombre completo"  >
          <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
        </CustomFormItem>

        <CustomFormItem name="buyEmail" label="Email de compra" >
          <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
        </CustomFormItem>

        <CustomFormItem name="userEmail" label="Email de usuario"  >
          <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
        </CustomFormItem>

        <CustomFormItem name="giftEmail" label="Email de regalo"  >
          <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
        </CustomFormItem>

      </Form>
    </div>
  )
}
