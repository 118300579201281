import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { AuthRouter } from './public/AuthRouter'
import { SalesRoutes, ProfileRoutes, SalesListRoutes, ProductsRoutes } from 'features/routes'
import { ProtectedRoutes } from './ProtectedRoutes'
import { Error404 } from '../common/components/organisms/Error404/Error404'
import { PermissionsError } from '../common/components/organisms/PermissionsError/PermissionsError'
import { HomeRoute } from './HomeRoute'
import { PRODUCTS_LIST_URL, PROFILE_URL, SALES_LIST_URL, SALES_URL } from 'common/utils/constants'

export const Router = () => {
  return (
    <Routes>
      <Route
        path="/login/*"
        element={
          <PublicRoute>
            <AuthRouter />
          </PublicRoute>
        }
      />
      <Route path="/no-permissions" element={<PermissionsError />} />
      <Route path="/404" element={<Error404 />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <ProtectedRoutes>
              <Routes>
                <Route path="/" element={<HomeRoute />} />
              </Routes>
              <SalesRoutes path={SALES_URL} />
              <SalesListRoutes path={SALES_LIST_URL} />
              <ProductsRoutes path={PRODUCTS_LIST_URL} />
              <ProfileRoutes path={PROFILE_URL} />
            </ProtectedRoutes>
          </PrivateRoute>
        }
      />
    </Routes>
  )
}
