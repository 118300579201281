const develop = {
  endpoints: {
    backend: process.env.REACT_APP_PUBLIC_API_URL,
    backend_aws: process.env.REACT_APP_PUBLIC_API_URL,
    iam: process.env.REACT_APP_PUBLIC_API_URL
  }
}
const configs = {
  develop
}

let environment = process.env.REACT_APP_ENV || 'develop'

export const setEnvironment = customEnvironment => (environment = customEnvironment)
export const config = configs[environment]
export const API_URL = process.env.REACT_APP_PUBLIC_API_URL
