import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SALES_LIST_URL } from 'common/utils/constants'
import { TableContext } from 'services/providers/table-provider'
import { CustomRangePicker, CustomSelector, InputSearch } from 'common/molecules'
import { CustomTable } from 'common/organisms'
import { INITIAL_SALES_TABLE_FILTERS, SalesTableColumns } from './tableHelper'
import { useSales } from 'services/hooks/useSales'
import SectionWithTable from 'common/components/templates/SectionWithTable/SectionWithTable'
import { IDropdownItem } from 'types/IDropdownItem'
import moment from 'moment'

export const SalesListTable = () => {
  const navigate = useNavigate()
  const { filter = INITIAL_SALES_TABLE_FILTERS, addFilters, setupInitialFilter } = useContext(TableContext)
  const { data: sales, isLoading: areSalesBeingFetched } = useSales(filter)
  const [orderSearch, setOrderSearch] = useState<string>(filter.orderId)
  const [emailSearch, setEmailSearch] = useState<string>('')

  const USER_ACTIVE = 'active'
  const USER_INACTIVE = 'inactive'

  useEffect(() => {
    setOrderSearch(filter.orderId)
    setEmailSearch(filter.buyerEmail)
  }, [filter])

  const userFilterOptions: IDropdownItem[] = [
    {
      key: USER_ACTIVE,
      name: 'Activo'
    },
    {
      key: USER_INACTIVE,
      name: 'Inactivo'
    }
  ]
  const STORAGE_SALES_FILTER_KEY = 'salesListTableFilter'
  useEffect(() => {
    if (localStorage.getItem(STORAGE_SALES_FILTER_KEY)) {
      addFilterAndClearFromStorage()
    } else {
      setupInitialFilter(INITIAL_SALES_TABLE_FILTERS)
    }
  }, [])

  const addFilterAndClearFromStorage = async () => {
    await addFilters(JSON.parse(localStorage.getItem(STORAGE_SALES_FILTER_KEY)!), false)
    localStorage.removeItem(STORAGE_SALES_FILTER_KEY)
  }

  const handleViewDetail = (id: string, appId: string) => {
    // Save filters, and navigate after that
    localStorage.setItem(STORAGE_SALES_FILTER_KEY, JSON.stringify(filter))
    navigate(`${SALES_LIST_URL}/${id}?appId=${appId}`)
  }

  const handleUserFilterChange = (searchFilter: any) => {
    addFilters({ userStatus: searchFilter === USER_ACTIVE ? true : false })
  }

  const handleSearchFilterChange = (_searchFilter: string) => {
    addFilters({ orderId: orderSearch })
  }

  const handleSearchFilterMailChange = (_searchFilter: string) => {
    addFilters({ buyerEmail: emailSearch })
  }

  const handleSearchByDate = (_: any, dateFilter: any) => {
    if (!dateFilter[0].length || !dateFilter[1].length) {
      addFilters({
        createdFrom: null,
        createdTo: null
      })
    } else {
      addFilters({
        createdFrom: moment(dateFilter[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        createdTo: moment(dateFilter[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      })
    }
  }

  const handleClearFilters = () => {
    localStorage.removeItem(STORAGE_SALES_FILTER_KEY)
    setupInitialFilter(INITIAL_SALES_TABLE_FILTERS)
  }

  return (
    <>
      <SectionWithTable
        title="VENTAS"
        showClear
        onClear={handleClearFilters}
        controls={
          <>
            <CustomRangePicker
              allowClear
              placeholder={['INICIO', 'FIN']}
              onChange={handleSearchByDate}
              disabledFutureDays
              className={''}
              popupClassName={''}
              aria-label="Filtro de rango de fecha"
              value={[
                filter.createdFrom ? moment(filter.createdFrom) : null,
                filter.createdTo ? moment(filter.createdTo) : null
              ]}
            />
            <InputSearch
              placeholder="# ORDEN"
              onSearch={handleSearchFilterChange}
              containerClassName={'w-200px]'}
              aria-label="Filtro de # ORDEN"
              value={orderSearch}
              onChange={(e: any) => setOrderSearch(e.target.value)}
            />
            <InputSearch
              placeholder="Mail de compra"
              onSearch={handleSearchFilterMailChange}
              containerClassName={'w-[200px]'}
              aria-label="Filtro de mail"
              value={emailSearch}
              onChange={(e: any) => setEmailSearch(e.target.value)}
            />

            <CustomSelector
              allowClear
              className="w-[200px]"
              options={userFilterOptions}
              value={userFilterOptions?.filter(item => filter?.userStatus === (item.key === USER_ACTIVE)) || []}
              placeholder="Estado de Usuario"
              onChange={handleUserFilterChange}
              aria-label="Filtro de estado de usuario"
            />
          </>
        }
        table={
          <CustomTable
            withPagination
            loading={areSalesBeingFetched}
            columns={SalesTableColumns(handleViewDetail)}
            data={sales?.items}
            totalElements={sales?.totalItems}
            initialPageSize={INITIAL_SALES_TABLE_FILTERS.size}
            defaultSort={INITIAL_SALES_TABLE_FILTERS.sort}
          />
        }
      />
    </>
  )
}
