import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Styles from './custom-selector.module.scss'

const { Option, OptGroup } = Select

export const CustomSelector = ({
  placeholder = '',
  loading = false,
  options = [],
  label = '',
  optionGroups = false,
  className = '',
  optionClassName = '',
  uppercasePlaceholder = true,
  suffixIcon = null,
  disabled = false,
  maxTagCount = 'responsive',
  omitValues = true,
  showSearch = false,
  ...rest
}) => {
  return (
    <>
      {label !== '' && <label>{label}</label>}
      <Select
        className={classNames(
          Styles.selector,
          {
            [Styles['--uppercasePlaceholder']]: uppercasePlaceholder,
            [Styles['--noSuffixIcon']]: !suffixIcon
          },
          className
        )}
        getPopupContainer={trigger => trigger.parentNode}
        showArrow
        virtual={false}
        showSearch={showSearch}
        disabled={loading || disabled}
        loading={loading}
        placeholder={placeholder}
        maxTagCount={omitValues && (maxTagCount ?? 0)}
        maxTagPlaceholder={
          omitValues &&
          (maxTagCount
            ? omittedValues => `+ ${omittedValues.length} ...`
            : omittedValues => `${placeholder}: (${omittedValues.length})`)
        }
        {...rest}
      >
        {!optionGroups &&
          options?.map((option, index) => (
            <Option value={option.value} key={option.key || index} className={optionClassName}>
              {option.name}
            </Option>
          ))}
        {optionGroups &&
          options?.map((optionGroup, groupIndex) => (
            <OptGroup label={optionGroup.name} key={optionGroup.key || groupIndex}>
              {optionGroup?.options?.map((option, index) => (
                <Option
                  value={option.value}
                  key={`${optionGroup.key}${option.key}` || `${optionGroup.key}${index}`}
                  className={optionClassName}
                >
                  {option.name}
                </Option>
              ))}
            </OptGroup>
          ))}
      </Select>
    </>
  )
}

CustomSelector.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  loading: PropTypes.bool,
  maxTagCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  omitValues: PropTypes.bool,
  optionClassName: PropTypes.string,
  optionGroups: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  showSearch: PropTypes.bool,
  suffixIcon: PropTypes.node,
  uppercasePlaceholder: PropTypes.bool
}
