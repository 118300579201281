import React from 'react'
import Styles from './title-page.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const TitlePage = ({ text, className }) => <h1 className={classnames(Styles.text, className)}>{text}</h1>

TitlePage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
}
