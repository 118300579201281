import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SALES_URL } from 'common/utils/constants'
import { TableContext } from 'services/providers/table-provider'
import Styles from './sales-table.module.scss'
import { DownloadCSV } from 'common/atoms'
import { CustomRangePicker, CustomSelector, InputSearch } from 'common/molecules'
import { CustomTable } from 'common/organisms'
import { useGetCountries } from 'services/hooks/useCountries'
import { INITIAL_SALES_TABLE_FILTERS, SalesTableColumns } from './tableHelper'
import { useSales } from 'services/hooks/useSales'
import SectionWithTable from 'common/components/templates/SectionWithTable/SectionWithTable'
import { useGetPaymentsStatus } from 'services/hooks/usePaymentStatus'
import { IDropdownItem } from 'types/IDropdownItem'
import { useProducts } from 'services/hooks/useProducts'
import moment from 'moment'
import { downloadAPICSVFile } from 'services/hooks/useSalesDownload'
import { APIEndpoints } from 'services/APIEndpoints'

export const SalesTable = () => {
  const navigate = useNavigate()
  const { filter = INITIAL_SALES_TABLE_FILTERS, addFilters, setupInitialFilter } = useContext(TableContext)
  const getCountriesQuery = useGetCountries()
  const getPaymentsEnumsQuery = useGetPaymentsStatus()
  const getProductsSKUSQuery = useProducts()
  const { data: sales, isLoading: areSalesBeingFetched } = useSales(filter)
  const [locationFilter, setLocationFilter] = useState<IDropdownItem[]>()
  const [paymentFilter, setPaymentFilter] = useState<IDropdownItem[]>()
  const [productsFilter, setProductsFilter] = useState<IDropdownItem[]>()
  const STORAGE_FILTER_KEY = 'salesTableFilter'
  const [orderSearch, setOrderSearch] = useState<string>(filter.orderId)

  useEffect(() => {
    if (localStorage.getItem(STORAGE_FILTER_KEY)) {
      addFilterAndClearFromStorage()
    } else {
      setupInitialFilter(INITIAL_SALES_TABLE_FILTERS)
    }
  }, [])

  useEffect(() => {
    setOrderSearch(filter.orderId)
  }, [filter])

  const addFilterAndClearFromStorage = async () => {
    await addFilters(JSON.parse(localStorage.getItem(STORAGE_FILTER_KEY)!), false)
    localStorage.removeItem(STORAGE_FILTER_KEY)
  }

  const handleViewDetail = (number: number, appId: string) => {
    // Save filters, and navigate after that
    localStorage.setItem(STORAGE_FILTER_KEY, JSON.stringify(filter))
    navigate(`${SALES_URL}/${number}?appId=${appId}`)
  }

  useEffect(() => {
    if (!getCountriesQuery.isLoading) {
      setLocationFilter(getCountriesQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))

    }
  }, [getCountriesQuery.data])



  useEffect(() => {
    if (!getProductsSKUSQuery.isLoading) {
      setProductsFilter(getProductsSKUSQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))
    }
  }, [getProductsSKUSQuery.data])

  useEffect(() => {
    if (!getPaymentsEnumsQuery.isLoading) {
      setPaymentFilter(getPaymentsEnumsQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))
    }
  }, [getPaymentsEnumsQuery.data])

  const handleChangeSerialNumberSelector = (searchFilter: any) => {
    addFilters({ productSku: searchFilter?.toString() })
  }
  const handlePaymentFilterChange = (searchFilter: any) => {
    addFilters({ paymentStatuses: searchFilter?.toString() })
  }

  const handleSearchFilterChange = (_searchFilter: string) => {
    addFilters({ orderId: orderSearch })
  }

  const handleSearchByOrigin = (originFilter: any) => {
    if (!originFilter.length) {
      addFilters({
        countryIds: null
      })
      return
    }
    const countries: any[] = []
    originFilter?.forEach((destiny: any) => {
      countries.push(destiny.replace('C', ''))
    })
    if (countries.length) {
      addFilters({
        countryIds: countries.length ? countries.join(',') : null,
      })
    }
  }

  const handleSearchByDate = (_: any, dateFilter: any) => {
    if (!dateFilter[0].length || !dateFilter[1].length) {
      addFilters({
        createdFrom: null,
        createdTo: null
      })
    } else {
      addFilters({
        createdFrom: moment(dateFilter[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        createdTo: moment(dateFilter[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      })
    }
  }

  const handleClearFilters = () => {
    localStorage.removeItem(STORAGE_FILTER_KEY)
    setupInitialFilter(INITIAL_SALES_TABLE_FILTERS)
  }

  const getSelectedValuesFromStorage = () => {
    const filteredFile = filter?.countryIds?.split(',').map((word: string) => /* 'C' + */ word.trim()) || []
    return filteredFile.length ? filteredFile : []
  }

  return (
    <>
      <SectionWithTable
        title="VENTAS"
        showClear
        onClear={handleClearFilters}
        controls={
          <>
            <CustomRangePicker
              allowClear
              placeholder={['INICIO', 'FIN']}
              onChange={handleSearchByDate}
              disabledFutureDays
              className={Styles.rangePicker}
              popupClassName={Styles.rangePickerDropdown}
              aria-label="Filtro de rango de fecha"
              value={[
                filter.createdFrom ? moment(filter.createdFrom) : null,
                filter.createdTo ? moment(filter.createdTo) : null
              ]}
            />
            <InputSearch
              placeholder="# ORDEN"
              onSearch={handleSearchFilterChange}
              containerClassName={Styles.inputSearchContainer}
              aria-label="Filtro de # ORDEN"
              value={orderSearch}
              onChange={(e: any) => setOrderSearch(e.target.value)}
            />

            <CustomSelector
              allowClear
              placeholder="SKU"
              options={productsFilter}
              loading={getProductsSKUSQuery.isLoading}
              onChange={handleChangeSerialNumberSelector}
              value={productsFilter?.filter(item => filter.productSku?.includes(item.key)) || []}
              className={Styles.serieSelector}
              optionClassName={Styles.serieOption}
              maxTagCount="responsive"
              aria-label="Filtro de SKU"
            />
            <CustomSelector
              allowClear
              mode="multiple"
              options={paymentFilter}
              value={paymentFilter?.filter(item => filter.paymentStatuses?.includes(item.key)) || []}
              loading={getPaymentsEnumsQuery.isLoading}
              placeholder="Estado"
              className={Styles.orderStateSelector}
              onChange={handlePaymentFilterChange}
              optionClassName={Styles.orderStateOption}
              aria-label="Filtro de estado"
            />

            <CustomSelector
              allowClear
              showSearch
              placeholder="PAÍS"
              options={locationFilter}
              loading={getCountriesQuery.isLoading}
              onChange={handleSearchByOrigin}
              mode="multiple"
              className={Styles.originSelector}
              optionClassName={Styles.originOption}
              aria-label="Filtro de País"
              value={getSelectedValuesFromStorage()}
            />
          </>
        }
        table={
          <CustomTable
            withPagination
            loading={areSalesBeingFetched}
            columns={SalesTableColumns(handleViewDetail)}
            data={sales?.items}
            totalElements={sales?.totalItems}
            initialPageSize={INITIAL_SALES_TABLE_FILTERS.size}
            defaultSort={INITIAL_SALES_TABLE_FILTERS.sort}
          />
        }
        footer={
          <DownloadCSV
            apiCall={() => downloadAPICSVFile('sales', `${APIEndpoints.SALES_LIST_DOWNLOAD_REPORTS}`, filter)}
          />
        }
      />
    </>
  )
}
