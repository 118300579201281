import { useContext } from 'react'
import { UserContext } from 'services/providers/user-context'
import { UserMenuItems } from './menuItems'

export const UserHelpers = () => {
  const { user } = useContext(UserContext)
  const canUpdate = () => isMasterAdmin() || isAdmin()
  const canDelete = () => isMasterAdmin() || isAdmin()
  const canCreate = () => isMasterAdmin() || isAdmin()
  const hasPermissionFor = permission => user.permissions.includes(permission)
  const canView = () => false
  const canAddToCart = () => false
  const isViewer = () => user.role === 'ROLE_VIEWER'
  const isAdmin = () => user.role === 'ROLE_ADMIN'
  const isMasterAdmin = () => user.roles?.includes('ROLE_ADMIN_MASTER')
  const pageViewPermission = paramRoles => {
    return paramRoles?.some(role => user.roles?.includes(role))
  }

  // Find the url in menu items
  // if exist, should check the permission
  // if not exist is valid because dont have permission role validation
  const hasRouteAccess = path => {
    const items = UserMenuItems.secondaryNavbar
    const menu = items?.find(item => item.url === path)
    return menu && menu.roles?.length ? pageViewPermission(menu.roles) : true
  }

  return {
    canUpdate,
    canDelete,
    canCreate,
    hasPermissionFor,
    canView,
    canAddToCart,
    isViewer,
    isMasterAdmin,
    isAdmin,
    pageViewPermission,
    hasRouteAccess
  }
}

export default UserHelpers
