export const APIEndpoints = {
  LOGIN: '/auth/login',
  CARRIERS: '/carriers',
  COUNTRIES: '/countries',
  PAYMENTS_LIST: '/enums/payment',
  PRODUCTS: '/products',
  REGIONS: '/regions',
  SALES_LIST: '/orders/reports/sales',
  ORDER_DETAIL: '/orders',
  SALES_LIST_DOWNLOAD_REPORTS: '/orders/reports/sales/download',
  PRODUCTS_PRICES_LIST: '/products/reports/prices',

}
