import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from 'services/providers/user-context.jsx'
import { LOGIN_URL } from 'common/utils/constants'
import img from 'assets/imgs/avatar1.png'
import Styles from './router.module.scss'
import { Layout } from 'antd'
const { Content } = Layout
import PropTypes from 'prop-types'
import { Header, Sidebar } from 'common/organisms'

const struct = children => {
  const { user } = useContext(UserContext)
  const { menus } = user.primaryNavbar ? user.primaryNavbar : { menus: null }

  return (
    <Layout className={Styles.privateRoutesContent}>
      <Sidebar />
      <Layout className={Styles.privateRoutesContent__main}>
        <Header topbarEntries={menus} name={user.firstName} img={img} />
        <Content
          style={{
            margin: '0 40px'
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}
export const PrivateRoute = ({ children }) => {
  const { user } = useContext(UserContext)
  const isAuthenticated = !!user.accessToken
  return isAuthenticated ? struct(children) : <Navigate to={LOGIN_URL} />
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
