import React from 'react'

import { HOME_URL } from 'common/utils/constants'
import Styles from './error-404.module.scss'
import { Layout } from 'antd'
import logoMessi from '../../../../assets/imgs/logo-messi.svg'
import Error404Icon from '../../../../assets/imgs/404-error-icon.svg'
import { CustomButton } from '../../atoms/CustomButton/CustomButton'

export const Error404 = () => (
  <Layout className={Styles.ErrorPage}>
    <div className={Styles.ImgContainer}>
      <img src={logoMessi} alt="logo-messi" className={Styles.logo} />
      <img
        src={Error404Icon}
        alt="404-error-icon"
        style={{ width: '200px', marginTop: '2rem', marginBottom: '4rem' }}
      />
    </div>
    <h1>Error 404</h1>
    <div className="text">
      <p>
        ooops! página no encontrada! <br /> esta página no exite o ha sido removida!
      </p>
      <CustomButton className={Styles.button} shape="round" ghost type="button" href={HOME_URL}>
        Volver al home →
      </CustomButton>
    </div>
  </Layout>
)
