import TextWithClipboardButton from 'common/components/molecules/TextWithClipboardButton/TextWithClipboardButton'
import { CustomFormItem, CustomInput } from 'common/molecules'
import { CustomTable } from 'common/organisms'
import { DATE_FORMATS } from 'common/utils/constants'
import { getLocalString } from 'common/utils/func-helpers'
import React from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Form, message } from 'antd'
import { useParams } from 'react-router-dom'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { INFT } from 'types/IOrderSale'

export const DigitalExperienceNFT = () => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data } = useSaleDetail(id || '', appId || '')

  const TableColumns = () => [
    {
      title: 'NFT ADDRESS',
      dataIndex: 'nft',
      key: '1',
      width: 40,
      align: 'left',
      render: (nft: INFT) => <div className='w-fit'><TextWithClipboardButton text={nft.contractAddress} abreviate={false} /></div>
    },
    {
      title: 'Fecha de Minteo',
      width: 40,
      dataIndex: 'nft',
      key: '2',
      render: (nft: INFT) => getLocalString(nft.mintingDate, DATE_FORMATS.DMY),
    }
  ]

  const copyHandler = () => {
    message.success('Texto copiado')
    navigator.clipboard.writeText(data?.customer.wallet || '')
  }


  return (
    <Form className='flex flex-col gap-[16px]'
      initialValues={{ address: data?.customer.wallet }}
    >

      <CustomFormItem name="address" label="Owner address" className=" w-[400px] font-bold"  >
        <CustomInput
          paddingBlock="0.5em"
          disabled
          value='t'
          placeholder="--"
          suffix={<CopyOutlined
            onClick={() => copyHandler()}
            className='ml-1'
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          }
        />
      </CustomFormItem>
      <CustomTable
        loading={false}
        withPagination={false}
        columns={TableColumns()}
        data={data?.items.filter(item => item.nft) || []}

        totalElements={data?.items.filter(item => item.nft) || []}
      />
    </Form>
  )
}
