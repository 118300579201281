import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

import Styles from './loader.module.scss'

export const Loader = ({ loading, text, icon, size, children }) => (
  <Spin
    className={Styles.loadingSpinner}
    tip={text}
    indicator={icon}
    spinning={loading}
    size={size}
    delay={100}
    aria-label="spinner"
  >
    {children}
  </Spin>
)

Loader.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.node
}
