import React, { useContext, useEffect } from 'react'
import { message } from 'antd'
import logo from 'assets/imgs/logo-messi.svg'
import loginImage from 'assets/imgs/messi-login.png'
import { UserContext } from 'services/providers/user-context.jsx'
import { EXPIRED_SESSION_MESSAGE } from 'common/utils/validation-messages'
import { EXPIRED_SESSION } from 'common/utils/constants'
import Styles from './login-view.module.scss'
import { LoginForm } from 'common/organisms'
import { UserMenuItems } from 'common/utils/menuItems'
import { useLogin } from 'services/hooks/useLogin'
import { handleErrorCodes } from 'common/utils/api.errors'

const { login } = useLogin()

const expiredSession = () => {
  message.error(EXPIRED_SESSION_MESSAGE)
  localStorage.removeItem(EXPIRED_SESSION)
}

export const LoginView = () => {
  const { setUser } = useContext(UserContext)
  const expiredSessionFlag = localStorage.getItem(EXPIRED_SESSION)

  useEffect(() => {
    expiredSessionFlag && expiredSession()
  }, [expiredSessionFlag])

  const loginHandler = async values => {
    try {
      const response = await login(values)
      setUser({
        type: 'SET_USER',
        payload: {
          ...response.user,
          accessToken: response.token,
          secondaryNavbar: {
            menus: UserMenuItems.secondaryNavbar.filter(item =>
              item.roles?.some(role => response.user.roles?.includes(role))
            )
          },
          primaryNavbar: { menus: UserMenuItems.primaryNavbar }
        }
      })
      return null
    } catch (error) {
      return handleErrorCodes(error.message)
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.container__login}>
        <div className={Styles.container__login__logoContainer}>
          <img className={Styles.container__login__logoContainer__logo} src={logo} alt="logo-messi" />
        </div>

        <h1 className={Styles.container__login__title}>BIENVENIDOS</h1>

        <LoginForm className={Styles.container__login__form} loginHandler={loginHandler} />
      </div>
      <div className={Styles.container__messiImageContainer}>
        <img className={Styles.container__messiImageContainer__image} src={loginImage} alt="messi-login" />
      </div>
    </div>
  )
}
