import React from 'react'
import { Breadcrumb } from 'common/atoms'
import { TableProvider } from 'services/providers/table-provider'
import { SalesListTable } from '../../organisms/SalesTable/SalesTable'
import { SALES_LIST_URL } from 'common/utils/constants'

export const SalesList = () => {
  return (
    <TableProvider>
      <Breadcrumb breadcrumbs={[{ title: 'Ventas', href: SALES_LIST_URL }]} />
      <SalesListTable />
    </TableProvider>
  )
}
