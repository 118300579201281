import React from 'react'
import { CustomTable } from 'common/organisms'
import { IOrderDetail } from 'types/IOrderSale'

interface IProps {
  order: IOrderDetail
}

export const SaleDetailTable = (props: IProps) => {
  const SaleDetailTableColumns = () => [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      width: 50
    },
    {
      title: 'Sku',
      dataIndex: 'sku',
      key: '2',
      width: 70
    },
    {
      title: 'Producto',
      dataIndex: 'name',
      key: '3',
      width: 150
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: '4',
      width: 50,
      render: (serie: string) => <span className="text-[#5584FF]">{serie}</span>
    }
  ]

  return (
    <>
      <CustomTable
        loading={false}
        withPagination={false}
        columns={SaleDetailTableColumns()}
        data={props.order.items}
        totalElements={0}
      />
    </>
  )
}
