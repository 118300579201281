import { DatePicker } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import Styles from './custom-range-picker.module.scss'

const { RangePicker } = DatePicker

export const CustomRangePicker = ({ className, disabledFutureDays, popupClassName, ...rest }) => {
  const disabledDate = current => {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  return (
    <RangePicker
      className={classnames(className, Styles.container)}
      popupClassName={popupClassName}
      disabledDate={disabledFutureDays && disabledDate}
      {...rest}
    />
  )
}

CustomRangePicker.propTypes = {
  className: PropTypes.string,
  disabledFutureDays: PropTypes.bool,
  popupClassName: PropTypes.string
}
