import { useQuery } from '@tanstack/react-query'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IOrderDetail } from 'types/IOrderSale'

export const useSaleDetail = (id: number | string, appId: string) => {
  const getSaleDetailFromApi = async (): Promise<IOrderDetail> => {
    return ApiRequest(addQueryString(`${API_URL}${APIEndpoints.ORDER_DETAIL}/${appId}/${id}`), 'GET')
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }

  return useQuery(['saleDetail-' + id + appId], getSaleDetailFromApi, {
    staleTime: 60000, // 1 minute,
    cacheTime: 60000, // 1 minute
  })
}
