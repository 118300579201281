import { Input } from 'antd'
import './custom-text-area.module.scss'

const { TextArea } = Input

import PropTypes from 'prop-types'
import classNames from 'classnames'

import Styles from './custom-text-area.module.scss'

export const CustomTextArea = ({ label = '', placeholder, maxLength, className, ...rest }) => {
  return (
    <>
      {label !== '' && <label>{label}</label>}
      <TextArea {...{ placeholder, maxLength, ...rest }} className={classNames(Styles.textArea, className)} />
    </>
  )
}

CustomTextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  showCount: PropTypes.bool
}
