import { useContext } from 'react'
import { message, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import Styles from './header.module.scss'
import { LOGIN_URL } from 'common/utils/constants'
import { processedErrorMessage } from 'services/api-calls/helpers'
import { UserContext } from 'services/providers/user-context'
import { useNavigate } from 'react-router-dom'
import { CustomButton, Icon } from 'common/atoms'
import { compare } from 'common/utils/func-helpers'
import { Layout } from 'antd'
import useLogin from 'services/hooks/useLogin'
import { useQueryClient } from '@tanstack/react-query'

const { Header: AntHeader } = Layout


export const Header = ({ name, topbarEntries }) => {
  const { logout } = useLogin()
  const queryClient = useQueryClient();

  const { setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const handleButtonClick = path => {
    if (path === LOGIN_URL) {
      return signOut()
    }

    return navigate(path)
  }

  const signOut = async () => {
    try {
      await logout()
      queryClient?.clear();

      setUser({ type: 'DELETE_USER' })
      navigate(LOGIN_URL)
    } catch (error) {
      const errorMessage = processedErrorMessage(error)
      message.error(errorMessage)
    }
  }

  const sortMenu = sortMenu => sortMenu.sort((a, b) => compare(a, b, 'order'))

  return (
    <AntHeader className={Styles.container}>
      <span className={Styles.container__content__name}>Hola {name}!</span>
      <div className={Styles.container__content__separator} />
      <div className={Styles.container__content__icons}>
        {sortMenu(topbarEntries).map((entry, index) => {
          if (entry.icon) {
            return (
              <Tooltip title={entry.description} key={index}>
                <CustomButton type="link" onClick={() => handleButtonClick(entry.url)}>
                  <Icon description={entry.description} type={entry.icon} />
                </CustomButton>
              </Tooltip>
            )
          }
          return null
        })}
      </div>
    </AntHeader>
  )
}

Header.propTypes = {
  name: PropTypes.string,
  topbarEntries: PropTypes.array
}
