import React from 'react'
import './App.less'
import { Router } from './Routes/router'
import { UserProvider } from './services/providers/user-context'
import esES from 'antd/es/locale/es_ES'
import { ConfigProvider, message } from 'antd'
import moment from 'moment'
import 'moment/locale/es'
import { ErrorBoundary, openErrorModal } from 'common/organisms'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './App.scss'
import { processedErrorMessage } from 'services/api-calls/helpers'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
      onError: error => {
        console.error(error)
        const errorMessage = processedErrorMessage(error)
        message.error(errorMessage)
      }
    },
    mutations: {
      onError: error => {
        const errorMessage = processedErrorMessage(error, error?.response?.data)
        openErrorModal({
          title: errorMessage?.title || errorMessage,
          contentMessage: errorMessage?.description,
          okText: 'Aceptar',
          variant: 'complete'
        })
      }
    }
  }
})

moment.locale('es')

const App = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ConfigProvider locale={esES}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </ConfigProvider>
      </UserProvider>
    </QueryClientProvider>
  </ErrorBoundary>
)

export default App
