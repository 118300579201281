import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'

export const useLogin = () => {

  const login = async (data: { email: string; password: string }) => {
    return ApiRequest(`${API_URL}${APIEndpoints.LOGIN}`, 'POST', {
      method: 'POST',
      body: {
        username: data.email,
        password: data.password
      }
    })
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e.message)
      })
  }

  const logout = () => {
    return {}
  }

  return {
    login,
    logout
  }
}

export default useLogin
