import React from 'react'
import { IOrderDetail } from 'types/IOrderSale'
import { SectionWithTabs } from 'common/organisms'
import { DigitalExperienceQR } from './DigitalExperienceQR'
import { DigitalExperienceNFT } from './DigitalExperienceNFT'

interface IProps {
  data?: IOrderDetail
}
export const DigitalExperienceForm = (_props?: IProps) => {

  const panelstabs = [
    {
      label: `QR`,
      key: '1',
      children: DigitalExperienceQR(),
    },
    {
      label: `NFT`,
      key: '2',
      children: DigitalExperienceNFT(),
    }]

  return (

    <div>
      <SectionWithTabs
        type='line'
        defaultActiveKey="1"
        fullWidthTabs={true}
        panels={panelstabs}
      />
    </div>

  )
}
