/* eslint-disable no-undef */
import React, { createContext, useState } from 'react'
import { INITIAL_TABLE_FILTERS, TABLE_INITIAL_PAGE } from 'common/utils/constants'

interface FilterContext {
  filter: any
  addFilters: (data: any, resetPage?: boolean) => void
  setFilter: (filter: any) => void
  currentPage: number
  setCurrentPage: (page: number) => void
  pageSize: number
  setCurrentPageSize: (size: number) => void
  setupInitialFilter: (filter: any) => void
}

const TableContext = createContext<FilterContext>({
  filter: null,
  addFilters: () => {},
  setFilter: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  setupInitialFilter: () => {},
  pageSize: INITIAL_TABLE_FILTERS.size,
  setCurrentPageSize: () => {}
})

const TableProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filter, setFilter] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setCurrentPageSize] = useState(INITIAL_TABLE_FILTERS.size)

  // USED TO SETUP A CUSTOM INITIAL STATE,  CALL THIS METHOD TO CLEAN ALL THE FILTERS
  const setupInitialFilter = (filter: any) => {
    setFilter(filter || INITIAL_TABLE_FILTERS)
    setCurrentPage(1)
    setCurrentPageSize(INITIAL_TABLE_FILTERS.size)
  }

  const addFilters = (newFilters: any, resetPage: boolean = true) => {
    if (resetPage) {
      setCurrentPage(1)
      setCurrentPageSize(INITIAL_TABLE_FILTERS.size)
    } else {
      setCurrentPage(newFilters.page)
      setCurrentPageSize(newFilters.size)
    }

    setFilter({
      ...filter,
      page: resetPage ? TABLE_INITIAL_PAGE : newFilters.page,
      ...newFilters
    })
  }

  return (
    <TableContext.Provider
      value={{
        filter,
        addFilters,
        setFilter,
        currentPage,
        setCurrentPage,
        setupInitialFilter,
        pageSize,
        setCurrentPageSize
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

export { TableContext, TableProvider }
