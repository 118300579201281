import { Error404 } from 'common/organisms'
import { LoginView } from 'features/pages'
import React from 'react'

import { Route, Routes } from 'react-router-dom'

export const AuthRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginView />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
