import * as React from 'react'
import PropTypes from 'prop-types'

const SvgTodoList = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m14.673 5.18-.032-.036a.182.182 0 0 0-.032-.032l-4.023-4a.449.449 0 0 0-.18-.103.479.479 0 0 0-.121-.025L.473.974a.474.474 0 0 0-.473.47V20.07a.468.468 0 0 0 .473.47h13.854a.475.475 0 0 0 .472-.47V5.544a.147.147 0 0 0 .009-.05.454.454 0 0 0-.137-.315h.002Zm-3.95-.156V2.578l2.461 2.446h-2.462Zm-.473.94h3.602V19.6H.945V1.914H9.78v3.58a.469.469 0 0 0 .472.47"
      fill="currentColor"
    />
    <path
      d="M2.48 9.48a.469.469 0 0 0 .473.469h8.536a.475.475 0 0 0 .473-.47.466.466 0 0 0-.292-.434.475.475 0 0 0-.18-.036H2.952a.475.475 0 0 0-.473.47ZM11.49 12.197H2.954a.475.475 0 0 0-.437.29.466.466 0 0 0 .256.614.475.475 0 0 0 .18.035h8.537a.475.475 0 0 0 .437-.29.468.468 0 0 0-.437-.649ZM11.49 15.264H2.954a.474.474 0 0 0-.473.47.468.468 0 0 0 .473.47h8.536a.474.474 0 0 0 .472-.47.468.468 0 0 0-.472-.47ZM3.558 6.772a.43.43 0 0 0 .307.121l.191-.021 2.005-1.99a.426.426 0 0 0 0-.598l-.16-.13-.344.024-1.699 1.685-.65-.647-.154-.12-.344.022-.104.104a.427.427 0 0 0-.128.311l.022.19 1.058 1.05Z"
      fill="currentColor"
    />
  </svg>
)

SvgTodoList.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgTodoList
