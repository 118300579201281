import React from 'react'
import classnames from 'classnames'
import Styles from './custom-input.module.scss'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import TextWithClipboardButton from '../TextWithClipboardButton/TextWithClipboardButton'

export const CustomInput = (
  { className = '', paddingInline = '0.5em', paddingBlock = '0.1em', theme = 'default', type = 'default', ...rest },
  ref
) => {
  const customInputStyles = {
    '--paddingInline': paddingInline,
    '--paddingBlock': paddingBlock
  }

  const inputTypes = {
    default: Input,
    password: Input.Password
  }

  const InputComponent = inputTypes[type]

  const customInputClassnames = [
    Styles.container__input,
    Styles[`--${theme}`],
    type === 'password' && Styles['--password']
  ]

  return (
    <div className={Styles.container}>
      <InputComponent
        style={customInputStyles}
        ref={ref}
        {...rest}
        className={classnames(...customInputClassnames, className)}
      />
    </div>
  )
}

CustomInput.displayName = 'CustomInput'
CustomInput.propTypes = {
  className: PropTypes.string,
  paddingBlock: PropTypes.string,
  paddingInline: PropTypes.string,
  rulesAnnotations: PropTypes.array,
  theme: PropTypes.string,
  type: PropTypes.string
}
