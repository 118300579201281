import { USER } from './constants'
import { jwtDecode } from 'jwt-decode'
import { handleTokenExpired, handleUserMissingInfo } from './func-helpers'

export function isTokenExpired(token: string): boolean {
  if (!token) return true
  const parsedToken = jwtDecode(token)
  const currentDate = new Date()
  if (!parsedToken?.exp) return true
  // calculate expiration date
  const expirationDate = new Date(parsedToken.exp * 1000)
  // Compare dates
  return expirationDate < currentDate
}

/**
 * Function to inyect the bearer token in the request
 * @param url
 * @param options
 * @returns
 */
const ApiRequest = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  options: any = {},
  contentType: 'application/json' | 'application/csv' = 'application/json'
) => {
  const unauthorizedRoute = url.includes('auth')
  const user = JSON.parse(localStorage.getItem(USER) || '{}')
  const token = user?.accessToken

  if (!token && !unauthorizedRoute) {
    handleUserMissingInfo()
    throw new Error()
  }

  if (token && !unauthorizedRoute) {
    if (isTokenExpired(token)) {
      handleTokenExpired()
      throw new Error()
    }
    const formattedToken = token.replace(/"/g, '')
    if (!options.headers) {
      options.headers = new Headers()
    }
    options.headers.append('Authorization', `Bearer ${formattedToken}`)
  }

  if (method === 'POST' || method === 'PUT') {
    if (options.body) {
      options.body = JSON.stringify(options.body)
    }
    if (!options.headers) {
      options.headers = new Headers()
    }
    options.headers.append('Content-Type', contentType)
  }

  return await fetch(url, { method, ...options })
    .then(status)
    .catch(e => {
      throw new Error(e.status || null)
    })
}

function status(res: Response) {
  if (!res.ok) {
    return Promise.reject(res)
  }
  return res
}

export default ApiRequest
