import { useQuery } from '@tanstack/react-query'
import { TABLE_INITIAL_PAGE } from 'common/utils/constants'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IDropdownItem } from 'types/IDropdownItem'
import { IPaginatedResponse } from 'types/IPaginatedResponse'

export const useProducts = () => {
  const getProductsFromApi = async (): Promise<IPaginatedResponse<any[]>> => {
    return ApiRequest(
      addQueryString(`${API_URL}${APIEndpoints.PRODUCTS}`, { page: TABLE_INITIAL_PAGE, size: 499 }),
      'GET'
    )
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }
  const getProcessedProductsSKUList = async (): Promise<IDropdownItem[]> => {
    return (await getProductsFromApi()).items.map(item => ({
      key: item.sku,
      name: item.sku
    }))
  }
  return useQuery(['productsList'], getProcessedProductsSKUList)
}
