import * as React from 'react'

const SvgEditS2 = props => (
  <svg viewBox="0 0 13 13" width="1em" height="1em" fill="inherit" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#EditS2_svg__a)" fill="inherit">
      <path d="M7.41 2.516.809 9.119a.263.263 0 0 0-.068.12l-.732 2.938a.261.261 0 0 0 .252.322c.02 0 .042-.002.063-.007l2.937-.732a.26.26 0 0 0 .12-.069L9.985 5.09 7.41 2.516ZM11.62 1.615 10.883.88c-.49-.491-1.347-.49-1.838 0l-.9.9 2.573 2.573.9-.9a1.29 1.29 0 0 0 .38-.919c0-.347-.134-.674-.38-.92Z" />
    </g>
    <defs>
      <clipPath id="EditS2_svg__a">
        <path fill="inherit" transform="translate(0 .5)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgEditS2
