import React, { useEffect, useContext, useState } from 'react'
import { TableContext } from 'services/providers/table-provider'
import { CustomSelector } from 'common/molecules'
import { CustomTable } from 'common/organisms'
import { ProductsTableColumns } from './tableHelper'
import SectionWithTable from 'common/components/templates/SectionWithTable/SectionWithTable'
import { IDropdownItem } from 'types/IDropdownItem'
import { useGetCountries } from 'services/hooks/useCountries'
import { useGetRegions } from 'services/hooks/useRegions'
import Styles from './table.module.scss'
import { useProducts } from 'services/hooks/useProducts'
import { TABLE_INITIAL_PAGE } from 'common/utils/constants'
import { useProductPrices } from 'services/hooks/useProductPrices'

export const ProductsListTable = () => {
  const INTIAL_PRODUCTS_TABLE_FILTERS = {
    page: TABLE_INITIAL_PAGE,
    size: 999,
  }

  const { filter = INTIAL_PRODUCTS_TABLE_FILTERS, addFilters, setupInitialFilter } = useContext(TableContext)
  const { data, isLoading: dataBeingFetched, isRefetching, refetch } = useProductPrices(filter)
  const [locationCountryFilter, setLocationCountryFilter] = useState<IDropdownItem[]>()
  const [locationRegionFilter, setLocationRegionFilter] = useState<IDropdownItem[]>()
  const getCountriesQuery = useGetCountries(true)
  const getRegionsQuery = useGetRegions(true)
  const [productsFilter, setProductsFilter] = useState<IDropdownItem[]>()
  const getProductsSKUSQuery = useProducts()

  useEffect(() => {
    if (!getCountriesQuery.isLoading) {
      setLocationCountryFilter(getCountriesQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))
    }
  }, [getCountriesQuery.data])

  useEffect(() => {
    if (!getRegionsQuery.isLoading) {
      setLocationRegionFilter(getRegionsQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))
    }
  }, [getRegionsQuery.data])

  useEffect(() => {
    if (!getProductsSKUSQuery.isLoading) {
      setProductsFilter(getProductsSKUSQuery.data?.sort((a, b) => b.name.localeCompare(a.name)))
    }
  }, [getProductsSKUSQuery.data])

  const handleSearchByCountry = (originFilter: any) => {
    if (!originFilter.length) {
      addFilters({
        country: null,
      })
      return
    }
    addFilters({
      country: originFilter
    })
  }

  const handleSearchByRegion = (originFilter: any) => {
    if (!originFilter?.length) {
      addFilters({
        region: null
      })
      return
    }
    addFilters({
      region: originFilter
    })
  }
  const handleChangeSKUSelector = (searchFilter: any) => {
    addFilters({ sku: searchFilter?.toString() })
  }

  const handleClearFilters = () => {
    setupInitialFilter(INTIAL_PRODUCTS_TABLE_FILTERS)
  }

  const handleRefresh = () => {
    refetch()
  }

  return (
    <>
      <SectionWithTable
        title="PRODUCTOS"
        showClear
        showRefresh
        onClear={handleClearFilters}
        onRefresh={handleRefresh}
        controls={
          <>
            <CustomSelector
              allowClear
              placeholder="SKU"
              options={productsFilter}
              loading={getProductsSKUSQuery.isLoading}
              onChange={handleChangeSKUSelector}
              value={productsFilter?.filter(item => filter.sku?.includes(item.key)) || []}
              className={Styles.serieSelector}
              optionClassName={Styles.serieOption}
              maxTagCount="responsive"
              aria-label="Filtro de SKU"
            />

            <CustomSelector
              allowClear
              placeholder="PAIS"
              showSearch
              options={locationCountryFilter}
              loading={getCountriesQuery.isLoading}
              onChange={handleSearchByCountry}
              className={Styles.originSelector}
              optionClassName={Styles.originOption}
              aria-label="Filtro de Pais"
            />

            <CustomSelector
              allowClear
              showSearch
              placeholder="REGIÓN"
              options={locationRegionFilter}
              loading={getRegionsQuery.isLoading}
              onChange={handleSearchByRegion}
              className={Styles.originSelector}
              optionClassName={Styles.originOption}
              aria-label="Filtro de Región"
            />
          </>
        }
        table={
          <CustomTable
            loading={dataBeingFetched || isRefetching}
            columns={ProductsTableColumns()}
            data={data?.items}
            totalElements={data?.totalItems}
            withPagination={false}
          />
        }
      />
    </>
  )
}
