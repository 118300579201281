import React from 'react'
import logoMessi from 'assets/imgs/logo-messi.svg'
import noAccessIcon from 'assets/imgs/no_access_icon.svg'
import { HOME_URL } from 'common/utils/constants'
import Styles from '../Error404/error-404.module.scss'
import { Layout } from 'antd'
import { CustomButton } from '../../atoms/CustomButton/CustomButton'

export const PermissionsError = () => (
  <Layout className={Styles.ErrorPage}>
    <div className={Styles.ImgContainer}>
      <img src={logoMessi} alt="logo-messi" style={{ width: '60px' }} />
      <img
        src={noAccessIcon}
        alt="sad-machine-icon"
        style={{ width: '200px', marginTop: '1rem', marginBottom: '4rem' }}
      />
    </div>
    <h1>ERROR DE ACCESO</h1>
    <div className="text">
      <p>LO SENTIMOS! NO TIENES PERMISO PARA ACCEDER A ESTA SECCIÓN.</p>
      <CustomButton className={Styles.button} shape="round" ghost type="button" href={HOME_URL}>
        Volver al home →
      </CustomButton>
    </div>
  </Layout>
)
