import React from 'react'
import * as LocalIcons from 'assets/icons'
import PropTypes from 'prop-types'

export const CustomIcon = ({ name = '', ...rest }) => {
  const ComponentToRender = LocalIcons[name]
  return ComponentToRender ? (
    <span {...rest}>
      <ComponentToRender />
    </span>
  ) : null
}

CustomIcon.propTypes = {
  name: PropTypes.string
}
