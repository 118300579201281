import { get } from 'lodash'
import messages from './messages.constants.json'
import { handleErrorCodes } from '../../common/utils/api.errors'

const { UNEXPECTED_ERROR, API_ERROR_401, API_ERROR_403, API_ERROR_500, STATUS_401, STATUS_403, STATUS_500 } = messages

export const createQueryString = query =>
  Object.keys(query)
    .filter(key => query[key] !== undefined && query[key] !== null)
    .map(key => `${key}=${query[key]}`)
    .join('&')

export const addQueryString = (url, query) => {
  const queryStringParameters = createQueryString(query || {})
  return query ? `${url}?${queryStringParameters}` : url
}

export const processedErrorMessage = (error, messageText) => {
  const status = get(error, 'status')
  const data = get(error, 'data')
  const errorCode = data?.code
  if (errorCode) return handleErrorCodes(errorCode)
  if (messageText) return messageText

  if (!status) return UNEXPECTED_ERROR
  switch (status.toString()) {
    case STATUS_401:
      return API_ERROR_401
    case STATUS_403:
      return API_ERROR_403
    case STATUS_500:
      return API_ERROR_500
    default:
      return messageText || error.data.message || UNEXPECTED_ERROR
  }
}
