import React from 'react'
import TextWithClipboardButton from 'common/components/molecules/TextWithClipboardButton/TextWithClipboardButton'
import { currencyFormat } from 'common/utils/func-helpers'
import { PAGE_SIZE, TABLE_INITIAL_PAGE } from 'common/utils/constants'

export const INITIAL_SALES_TABLE_FILTERS = {
  page: TABLE_INITIAL_PAGE,
  size: PAGE_SIZE,
  sort: 'total,desc'
}

export const ProductsTableColumns = () => [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: '1',
    width: 40,
    render: (id: any) => <TextWithClipboardButton text={id} abreviate={false} />
  },
  {
    title: 'Descripción',
    dataIndex: 'name',
    key: '2',
    width: 70
  },
  {
    title: 'Pais',
    dataIndex: 'country',
    key: '6',
    width: 40
  },
  {
    title: 'Región',
    dataIndex: 'region',
    key: '7',
    width: 40
  },
  {
    title: 'Precio Shopify',
    dataIndex: 'shopifyPrice',
    key: '6',
    width: 40,
    render: (shopifyPrice: string) => currencyFormat(shopifyPrice)
  },
  {
    title: 'Precio Mediants',
    dataIndex: 'mediantsPrice',
    key: '7',
    width: 40,
    render: (mediantsPrice: string) => currencyFormat(mediantsPrice)
  }, {
    title: 'Diferencia de Precios',
    dataIndex: 'delta',
    key: '6',
    width: 40,
    render: (delta: string) => <span className={`${(Number(delta) !== 0) ? "text-[#ff0000]" : ''}`}>{currencyFormat(delta || 0)}</span>
  }
]
