import React from 'react'
import { Error404 } from 'common/organisms'
import { Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ProductsList } from './components/pages/Products/ProductsList'
 
export const ProductsRoutes = ({ path = 'products' }) => {
  return (
    <Routes>
      <Route path={path}>
        <Route index element={<ProductsList />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}

ProductsRoutes.propTypes = {
  path: PropTypes.string
}
