import * as React from 'react'
import PropTypes from 'prop-types'

const SvgAlertIcon = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.5 0a12.5 12.5 0 1 0 0 25 12.5 12.5 0 0 0 0-25Zm1.034 6.587-.186 6.917a.859.859 0 0 1-.847.889.86.86 0 0 1-.847-.889l-.186-6.917a1.035 1.035 0 0 1 1.434-.98 1.034 1.034 0 0 1 .632.98Zm-1.034 11.8a1.107 1.107 0 1 1 1.058-.674 1.117 1.117 0 0 1-1.058.676v-.002Z"
      fill="currentColor"
    />
  </svg>
)

SvgAlertIcon.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgAlertIcon
