import * as React from 'react'
import PropTypes from 'prop-types'

const SvgBeneficios = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.936 7.42a1.335 1.335 0 0 0-.488-.667 1.348 1.348 0 0 0-.79-.254h-4.873l-1.507-4.607a1.348 1.348 0 0 0-2.555 0L6.218 6.498H1.34a1.348 1.348 0 0 0-1.275.922 1.326 1.326 0 0 0 .487 1.49l3.943 2.846-1.508 4.608a1.328 1.328 0 0 0 .49 1.494 1.372 1.372 0 0 0 1.578 0L9 15.011l3.945 2.847c.23.166.505.255.789.257a1.35 1.35 0 0 0 1.085-.55 1.33 1.33 0 0 0 .192-1.197l-1.507-4.61 3.939-2.848a1.328 1.328 0 0 0 .49-1.493l.003.003Zm-13.95 9.266 1.625-4.966a.515.515 0 0 0-.191-.582L1.168 8.064a.295.295 0 0 1-.106-.327.29.29 0 0 1 .279-.193h5.256a.524.524 0 0 0 .5-.36l1.625-4.967A.291.291 0 0 1 9 2.015a.294.294 0 0 1 .278.202l1.626 4.966a.518.518 0 0 0 .498.36h5.255a.296.296 0 0 1 .279.204.291.291 0 0 1-.107.326l-4.251 3.07a.519.519 0 0 0-.191.582l1.623 4.966a.288.288 0 0 1-.277.38.302.302 0 0 1-.172-.054l-4.254-3.069a.535.535 0 0 0-.615 0L4.44 17.013a.302.302 0 0 1-.348 0 .29.29 0 0 1-.106-.327Z"
      fill="currentColor"
    />
  </svg>
)

SvgBeneficios.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgBeneficios
