import React from 'react'
import { EyeOutlined } from '@ant-design/icons'
import TextWithClipboardButton from 'common/components/molecules/TextWithClipboardButton/TextWithClipboardButton'
import { CustomButton } from 'common/atoms'
import { capitalizeFirstLetter, currencyFormat, getLocalString } from 'common/utils/func-helpers'
import { DATE_FORMATS, PAGE_SIZE, TABLE_INITIAL_PAGE } from 'common/utils/constants'
import { IDropdownItem } from 'types/IDropdownItem'
import { IOrder } from 'types/IOrderSale'

export const INITIAL_SALES_TABLE_FILTERS = {
  page: TABLE_INITIAL_PAGE,
  size: PAGE_SIZE,
  sort: 'created_at,desc'
}

export const SalesTableColumns = (viewDetail: any) => [
  {
    title: '# Orden',
    dataIndex: 'number',
    key: '1',
    width: 60,
    render: (number: any) => <div className='w-fit'><TextWithClipboardButton abreviate={false} text={number} /></div>
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    key: '3',
    width: 35,
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    key: '4',
    width: 35,
    render: (status: string) => capitalizeFirstLetter(status)
  },
  {
    title: 'País',
    dataIndex: 'country',
    key: '4',
    width: 55
  },
  {
    title: 'Precio total',
    dataIndex: 'total',
    key: '6',
    width: 30,
    render: (total: string) => currencyFormat(total)
  },
  {
    title: 'Fecha compra',
    width: 40,
    dataIndex: 'createdAt',
    key: '10',
    render: (createdAt: number) => getLocalString(createdAt, DATE_FORMATS.DMY),
    sorter: true,
    sortFieldName: 'created_at'
  },
  {
    title: 'Acciones',
    dataIndex: 'number',
    width: 25,
    key: '12',
    render: (number: number, item: IOrder) => (
      <CustomButton
        type="link"
        inheritColor
        icon={
          <EyeOutlined
            style={{ fontSize: '1.2rem' }}
            onClick={() => viewDetail(number, item.appId)}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        children={undefined}
      />
    )
  }
]

export const getProcessedCarriersFilter = (carriers: any[]): IDropdownItem[] => {
  return (
    carriers.map(item => ({
      key: item.id,
      name: item.code.toString().toUpperCase()
    })) || []
  )?.sort((a, b) => b.name.localeCompare(a.name))
}
