import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'services/providers/user-context'
import Styles from './sidebar.module.scss'
import logoMessi from 'assets/imgs/logo-messi.svg'
import { compare } from 'common/utils/func-helpers'
import { Layout, Menu } from 'antd'
import classnames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomIcon } from 'common/atoms'
import UserHelpers from 'common/utils/user-helpers'
const { Sider } = Layout

export const Sidebar = () => {
  const { user } = useContext(UserContext)
  const { menus } = user.secondaryNavbar
  const location = useLocation()
  const [itemsProcessed, setItemsProcessed] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()
  const helpers = UserHelpers()

  const processMenuItems = items => {
    const itemsSorted = sortItems(items).filter(item => helpers.pageViewPermission(item.roles))
    return itemsSorted.map(entry => {
      const processedChildrens = processMenuItems(entry.items)
      const splitted = entry.icon.split('-')
      const uppercaseArray = splitted.map(word => word[0].toUpperCase() + word.slice(1))
      const wordInCamelCase = uppercaseArray.join('')
      return {
        key: entry.url,
        icon: <CustomIcon name={wordInCamelCase} className={Styles.container__menu__item__icon} />,
        children: processedChildrens.length > 0 && processedChildrens,
        label: entry.description,
        className: Styles.container__menu__item
      }
    })
  }

  const sortItems = items => {
    if (!items) return []
    return items.sort((a, b) => compare(a, b, 'order'))
  }

  const handleClick = ({ key }) => {
    navigate(key)
  }

  useEffect(() => {
    setItemsProcessed(processMenuItems(menus))
  }, [])

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      className={classnames(Styles.container, !collapsed && Styles['--notCollapsed'])}
    >
      <div className={Styles.container__logoContainer}>
        <img className={Styles.container__logoContainer__logo} src={logoMessi} alt="logo" />
      </div>
      <Menu
        selectedKeys={`/${location.pathname.split('/')[1]}`}
        onClick={handleClick}
        theme="dark"
        mode="inline"
        items={itemsProcessed}
        rootClassName={Styles.container__menu}
      />
    </Sider>
  )
}
