const defaultErrorCode = '500'

export const ERRORS = {
  [defaultErrorCode]: 'Hubo un error al realizar la operación',
  401: 'Los datos ingresados no son correctos'
}

export const handleErrorCodes = code => {
  if (code.toString() in ERRORS) {
    return ERRORS[code]
  }
  return ERRORS[defaultErrorCode]
}
