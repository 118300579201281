import { Profile } from 'features/pages'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'

export const ProfileRoutes = ({ path = 'profile' }) => {
  return (
    <Routes>
      <Route path={path}>
        <Route index element={<Profile />} />
      </Route>
    </Routes>
  )
}

ProfileRoutes.propTypes = {
  path: PropTypes.string
}
