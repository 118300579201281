import React from 'react'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { useParams } from 'react-router-dom'
import { SectionWithTabs } from 'common/organisms'
import { OrderItemLogisticDetails } from './OrderItemLogisticDetails'

export const LogisticForm = () => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data } = useSaleDetail(id || '', appId || '')

  const orderItems = data?.items

  return (
    <div>
      <SectionWithTabs
        type='line'
        defaultActiveKey="1"
        fullWidthTabs={false}
        panels={
          orderItems?.map((item, index) => {
            return {
              label: `Item: ` + item.id,
              key: index.toString(),
              children: <OrderItemLogisticDetails order={data!} orderItem={item} />,
            }
          })
        }
      />
    </div>
  )
}
