import { useState } from 'react'
import { Form } from 'antd'
import { CustomButton, CustomIcon } from 'common/atoms'
import { CustomInput, CustomFormItem } from 'common/molecules'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Styles from './login-form.module.scss'
import { useNavigate } from 'react-router-dom'

export const LoginForm = ({ className = '', loginHandler = () => ({}), ...rest }) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const handleSubmit = async data => {
    setIsLoginLoading(true)
    const error = await loginHandler(data)
    if (error) {
      setErrorMessages([...errorMessages, error])
    }
    setIsLoginLoading(false)
  }

  const handleChange = () => {
    const currentErrors = []
    form.getFieldsError().forEach(field => {
      if (field.errors[0]) {
        currentErrors.push(field.errors[0])
      }
    })
    if (currentErrors.length !== 0) {
      setErrorMessages(currentErrors)
    } else {
      setErrorMessages([])
    }
  }

  const handleResetPassword = () => navigate('reset-password')

  return (
    <Form
      form={form}
      onFieldsChange={() => handleChange()}
      className={classnames(className, Styles.form)}
      {...rest}
      onFinish={handleSubmit}
    >
      <CustomFormItem
        name="email"
        rules={[{ required: true, message: 'Por favor, ingresa tu usuario!' }]}
        className={Styles.form__userFormItem}
        hideError
      >
        <CustomInput
          theme="primary"
          placeholder="USUARIO"
          paddingInline="1.8em"
          paddingBlock="1.2em"
          className={Styles.form__userFormItem__input}
        />
      </CustomFormItem>
      <CustomFormItem
        name="password"
        rules={[{ required: true, message: 'Por favor, ingresa tu contraseña!' }]}
        className={Styles.form__passwordFormItem}
        hideError
      >
        <CustomInput
          className={Styles.form__passwordFormItem__input}
          theme="primary"
          type="password"
          placeholder="CONTRASEÑA"
          paddingInline="1.8em"
          paddingBlock="1.2em"
        />
      </CustomFormItem>
      <CustomFormItem className={Styles.form__submitButtonContainer}>
        <CustomButton
          className={Styles.form__submitButtonContainer__button}
          type="primary"
          shape="round"
          ghost
          loading={isLoginLoading}
          htmlType="submit"
        >
          INGRESAR →
        </CustomButton>
      </CustomFormItem>
      <div className={Styles.form__errorMessagesContainer}>
        {errorMessages.length > 0 && (
          <div className={Styles.form__errorMessagesContainer__errorMessages}>
            {errorMessages.map((errorMessage, index) => (
              <div
                className={Styles.form__errorMessagesContainer__errorMessages__errorMessage}
                key={index}
                role="alert"
              >
                <CustomIcon
                  name="AlertIcon"
                  className={Styles.form__errorMessagesContainer__errorMessages__errorMessage__icon}
                />
                <p className={Styles.form__errorMessagesContainer__errorMessages__errorMessage__text}>{errorMessage}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </Form>
  )
}

LoginForm.propTypes = {
  className: PropTypes.string,
  clearResponseError: PropTypes.func,
  loginHandler: PropTypes.func,
  responseError: PropTypes.string
}
