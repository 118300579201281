import * as React from 'react'
import PropTypes from 'prop-types'

const SvgClientes = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7.27 9.643c.864 0 1.71-.254 2.428-.731a4.35 4.35 0 0 0 1.611-1.95 4.318 4.318 0 0 0-.947-4.734 4.397 4.397 0 0 0-4.764-.942 4.366 4.366 0 0 0-1.962 1.6A4.324 4.324 0 0 0 2.899 5.3 4.335 4.335 0 0 0 4.18 8.37a4.39 4.39 0 0 0 3.09 1.273Zm0-7.491c.626 0 1.238.185 1.76.53.52.346.926.838 1.166 1.413a3.129 3.129 0 0 1-.687 3.43 3.186 3.186 0 0 1-3.452.682 3.163 3.163 0 0 1-1.421-1.159 3.133 3.133 0 0 1 .395-3.973 3.181 3.181 0 0 1 2.238-.923ZM14.356 13.286a10.07 10.07 0 0 0-7.09-2.914 10.07 10.07 0 0 0-7.09 2.914.596.596 0 0 0 .426 1.02c.16 0 .312-.062.425-.174a8.862 8.862 0 0 1 6.239-2.564c2.339 0 4.582.922 6.238 2.564a.604.604 0 0 0 1.027-.423.595.595 0 0 0-.176-.423"
      fill="currentColor"
    />
  </svg>
)

SvgClientes.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgClientes
