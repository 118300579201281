import React from 'react'
import PropTypes from 'prop-types'
import Styles from './spinner.module.scss'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'

export const Spinner = ({ children, text, loading = true, icon, size }) => (
  <div
    className={classNames(Styles.container, {
      [Styles['--centered']]: loading
    })}
  >
    <Spin className={Styles.loadingSpinner} tip={text} indicator={icon} spinning={loading} size={size} delay={100}>
      {!loading && children}
    </Spin>
  </div>
)

Spinner.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string
}

Spinner.defaultProps = {
  text: 'Cargando...',
  icon: <LoadingOutlined spin />,
  size: 'large'
}
