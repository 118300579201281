import PropTypes from 'prop-types'

export const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

ConditionalWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  condition: PropTypes.bool,
  wrapper: PropTypes.func
}
