import React from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import PropTypes from 'prop-types'

import Styles from './breadcrumb.module.scss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

export const Breadcrumb = ({ breadcrumbs }) => {
  const navigate = useNavigate()

  const handleBreadcrumbClick = breadcrumb => {
    if (breadcrumb.href) navigate(breadcrumb.href)
    if (breadcrumb.onClick) breadcrumb.onClick()
  }

  return (
    <AntBreadcrumb className={Styles.container} separator={<span className={Styles.container__separator}>/</span>}>
      {breadcrumbs.map((breadcrumb, index) => (
        <AntBreadcrumb.Item key={index} onClick={() => handleBreadcrumbClick(breadcrumb)}>
          <span
            className={classNames(
              Styles.container__item,
              (breadcrumb.href || breadcrumb.onClick) && Styles['--link'],
              !breadcrumb.href && !breadcrumb.onClick && Styles['--noLink']
            )}
          >
            {breadcrumb.title}
          </span>
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  )
}

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.array.isRequired
}
