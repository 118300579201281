import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { addQueryString } from 'services/api-calls/helpers'

export const downloadAPICSVFile = async (_fileName: string = '', url: string, filters: any): Promise<void> => {
  const URL = `${API_URL}${url}`

  return ApiRequest(addQueryString(URL, filters), 'GET', {}, 'application/csv').then(response => {
    const contentDisposition = response.headers.get('content-disposition')

    let filename = `${_fileName}.zip`
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"$/)

      if (filenameMatch) {
        filename = filenameMatch[1]
      }
    }

    return response.blob().then(blob => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  })
}
