import React, { useEffect } from 'react'
import { Card, Divider, Form } from 'antd'
import { CustomInput, CustomFormItem } from 'common/molecules'
import Styles from './styles.module.scss'
import { SaleDetailTable } from '../SaleDetailTable/SaleDetailTable'
import { useParams } from 'react-router-dom'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { Loader } from 'common/atoms'

export const ShippingForm = () => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data, isLoading: isLoading } = useSaleDetail(id || '', appId || '')
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const address = [
      data?.shippingAddress.street,
      data?.shippingAddress.city,
      data?.shippingAddress.state,
      data?.shippingAddress.zip
    ]
      .filter(value => value !== null && value !== '' && value !== undefined)
      .join(', ')

    form.setFieldsValue({ address: address })
    form.setFieldsValue({ shippingCountry: data?.shippingAddress.country.name })

  }, [data])

  const DividerStyles = { gridColumn: '1 / span 2', margin: '10px 0' }

  return (
    <Loader loading={isLoading}>
      <div className='flex w-full gap-[16px]'  >
        <Card size="small" title="Domicilio de entrega" style={{ width: '100%', backgroundColor: 'rgb(230 230 230)' }} headStyle={{ fontWeight: 700, fontSize: '16px' }}>
          <Form form={form} layout="vertical" className={Styles.form} initialValues={data}>

            <CustomFormItem name="shippingCountry" label="País"  >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

            <CustomFormItem
              name="address"
              label="Domicilio"
            >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

          </Form>
        </Card>
      </div>

      <Divider style={DividerStyles} />

      <p className="font-bold">Información de la orden</p>
      <div>
        {data && <SaleDetailTable order={data}></SaleDetailTable>}
      </div>
    </Loader>
  )
}
