import { FILENAME_CONTENT_DISPOSITION } from './validation-regular-exp'
import moment from 'moment'
import { EXPIRED_SESSION, LOGIN_URL, USER } from './constants'

export const compare = (element1, element2, key) => {
  if (!element1[key] && element1[key] !== 0) return 1
  if (!element2[key] && element2[key] !== 0) return -1
  return JSON.stringify(element1[key]).localeCompare(JSON.stringify(element2[key]))
}

export const isEmpty = object => Object.entries(object).length === 0

export const downloadPdf = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file]))
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.setAttribute('download', fileName)
  document.body.append(link)
  link.click()
  document.body.removeChild(link)
}

export const currencyFormat = value => {
  const formattedValue = parseFloat(value).toFixed(2);
  if (formattedValue < 0) {
    return `- $${Math.abs(formattedValue)}`;
  } else {
    return `$${formattedValue}`;
  }
};

export const dateFormat = date => {
  const format = new Date(date)
  return `${format.getUTCDate()}/${format.getUTCMonth() + 1}/${format.getUTCFullYear()}`
}

export const cleanObjectValues = object =>
  Object.entries(object).reduce(
    (accumulator, item) => (item[1] ? Object.assign({}, accumulator, { [item[0]]: item[1] }) : accumulator),
    {}
  )

export const arrayBufferToString = buffer => {
  const byteArray = new Uint8Array(buffer)
  let string = ''
  let cc = 0
  let numberBytes = 0
  for (let i = 0, length_ = byteArray.length; i < length_; ++i) {
    const v = byteArray[i]
    if (numberBytes > 0) {
      // 2 bit determining that this is a tailing byte + 6 bit of payload
      if ((cc & 192) === 192) {
        // processing tailing-bytes
        cc = (cc << 6) | (v & 63)
      } else {
        throw new Error('this is no tailing-byte')
      }
    } else if (v < 128) {
      // single-byte
      numberBytes = 1
      cc = v
    } else if (v < 192) {
      // these are tailing-bytes
      throw new Error('invalid byte, this is a tailing-byte')
    } else if (v < 224) {
      // 3 bits of header + 5bits of payload
      numberBytes = 2
      cc = v & 31
    } else if (v < 240) {
      // 4 bits of header + 4bit of payload
      numberBytes = 3
      cc = v & 15
    } else {
      // UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
      // but JS can only handle 16bit.
      throw new Error('invalid encoding, value out of range')
    }

    if (--numberBytes === 0) {
      string += String.fromCharCode(cc)
    }
  }
  if (numberBytes) {
    throw new Error("the bytes don't sum up")
  }
  return string
}

export const extractContentDispositionFilename = (response, filename) => {
  const r = new RegExp(FILENAME_CONTENT_DISPOSITION)
  return response.headers['content-disposition'] ? r.exec(response.headers['content-disposition'])[0] : filename
}

export const getMonthDescriptionByNumber = month => {
  const m = moment(new Date(null, month - 1)).format('MMMM')
  return m.toUpperCase()
}

export const isObjectEmpty = obj => {
  return Object.keys(obj).length === 0
}

export const toMilliseconds = (hrs, min, sec) => (hrs * 60 * 60 + min * 60 + sec) * 1000

export const addRequestToQueue = async (originalRequest, axios) => {
  return new Promise(function (resolve, reject) {
    window.failedRequests && window.failedRequests.push({ resolve, reject })
    !window.failedRequests && (window.failedRequests = [{ resolve, reject }])
  })
    .then(token => {
      originalRequest.headers['Authorization'] = 'Bearer ' + token
      return axios(originalRequest)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const handleTokenExpired = (error = 'token expired') => {
  localStorage.setItem(USER, null)
  localStorage.setItem(EXPIRED_SESSION, true)
  if (window.location.pathname !== LOGIN_URL) window.location = LOGIN_URL
}
export const handleUserMissingInfo = (error = 'missing user') => {
  localStorage.setItem(USER, null)
  if (window.location.pathname !== LOGIN_URL) window.location = LOGIN_URL
}

export const getDifferenceInDays = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1)
  return diffInMs / (1000 * 60 * 60 * 24)
}

export const processQueue = (error, token = null) => {
  window.failedRequests.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  window.failedRequests = []
}

export const getLocalString = (timestampInMilliseconds, format = 'DD/MM/yyyy HH:mm:ss') => {
  if (!timestampInMilliseconds) return null

  return moment.utc(timestampInMilliseconds).local().format(format) // hora local en formato HH:mm:ss
}

export const onLoadMetadataImage = (image, resolve) => {
  const height = image?.height
  const width = image?.width
  resolve({ height, width })
}

export const loadImage = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      const image = new Image()

      image.src = e.target.result

      image.onload = () => onLoadMetadataImage(image, resolve)

      image.onerror = function () {
        reject()
      }
    }
  })
}

export const loadImageFromUrl = url => {
  return new Promise((resolve, reject) => {
    const image = new Image()

    image.src = url

    image.onLoad = () => () => {
      const metadata = () => onLoadMetadataImage(image, resolve)
      resolve(metadata)
    }

    image.onerror = function (error) {
      reject(error)
    }
  })
}

export const onLoadedMetadataVideo = video => {
  const height = video?.videoHeight
  const width = video?.videoWidth
  return { height, width }
}

export const loadVideo = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      const url = e.target.result
      const video = document.createElement('video')
      video.src = url
      video.load()

      video.onloadedmetadata = function () {
        const metadata = onLoadedMetadataVideo(video)
        resolve(metadata)
      }

      video.onerror = function (error) {
        console.error(error)
        reject(error)
      }
    }
  })
}

export const loadVideoFromUrl = url => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')

    video.src = url
    video.load()

    video.onloadedmetadata = function () {
      const metadata = onLoadedMetadataVideo(video)
      resolve(metadata)
    }

    video.onerror = function (error) {
      console.error(error)
      reject(error)
    }
  })
}

// How to get video dimensions from url in javascript?

export const validateFileDimensions = async ({ file, correctWidth, correctHeight, isVideo }) => {
  if (!file) return false
  try {
    const { height, width } = isVideo ? await loadVideo(file) : await loadImage(file)
    return height == correctHeight && width == correctWidth
  } catch (error) {
    console.error(error)
  }
}

export const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = error => reject(error)
  })

export const orderByFields = (prop, arr) => {
  const _propSplitted = prop?.split('.')
  const _length = _propSplitted?.length

  arr?.sort((a, b) => {
    let i = 0
    while (i < _length) {
      a = a?.[_propSplitted?.[i]]
      b = b?.[_propSplitted?.[i]]
      i++
    }
    return a - b
  })
  return arr
}

export const getFileSizeFromUrl = async url => {
  fetch(url, { method: 'HEAD' }).then(result => {
    return result.headers.get('content-length')
  })
}

export const getFileName = url => {
  return decodeURI(url.split('/').pop())
}

export const getFileExtension = url => getFileName(url).split('.').pop()

export const sortLanguages = (languages = []) => languages.sort((a, b) => b.language.default - a.language.default)

export const generateRandomColor = () => {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return `rgb(${r}, ${g}, ${b})`
}

export const getEllipsisString = (str, maxLength, charsToShowStart, charsToShowEnd) => {
  if (str?.length > maxLength) {
    return str.substr(0, charsToShowStart) + '...' + str.substr(str.length - charsToShowEnd, str.length)
  }
  return str
}

export const formatNumberWithDecimalPoint = _value => {
  if (!_value && _value !== 0) return
  const _valueWithoutPoints = parseInt(_value.toString()?.replaceAll?.('.', ''))
  const _options = {
    useGrouping: true
  }

  return _valueWithoutPoints.toLocaleString('es', _options)
}

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
