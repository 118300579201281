import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Styles from './section-with-tabs.module.scss'
import { Tabs } from 'antd'

export const SectionWithTabs = ({ panels = [], fullWidthTabs = false, type = 'card', ...rest }) => {
  const classNames = classnames(Styles.tabs,  Styles[fullWidthTabs ? '--fullWidthTabs' : '--adjustWidthTabs'], type === 'line' && Styles['--lineType'])

  return (
    <Tabs size="large" type={type} className={classNames} tabBarGutter={0} moreIcon={null} {...rest} items={panels} />
  )
}

SectionWithTabs.propTypes = {
  fullWidthTabs: PropTypes.bool,
  panels: PropTypes.array,
  type: PropTypes.string
}
