import * as React from 'react'
import PropTypes from 'prop-types'

const SvgParametria = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.997 18.652H2.477a.478.478 0 0 0-.477.473.472.472 0 0 0 .477.474h17.52a.478.478 0 0 0 .477-.474.472.472 0 0 0-.477-.473ZM4.922 18.096h2.914a.48.48 0 0 0 .476-.474v-6.23a.474.474 0 0 0-.476-.474H4.922a.48.48 0 0 0-.477.474v6.234a.473.473 0 0 0 .477.47Zm2.437-.948H5.403v-5.286h1.96l-.004 5.286ZM9.749 18.096h2.914a.48.48 0 0 0 .477-.474V8.395a.472.472 0 0 0-.477-.473H9.749a.478.478 0 0 0-.477.473v9.231a.473.473 0 0 0 .477.474v-.004Zm2.438-.944h-1.961V8.869h1.96v8.283ZM14.872 18.096h2.915a.478.478 0 0 0 .476-.474V3.454a.472.472 0 0 0-.476-.473h-2.915a.477.477 0 0 0-.476.473v14.175a.472.472 0 0 0 .476.474v-.007Zm.478-14.168h1.96v13.224h-1.96V3.928Z"
      fill="currentColor"
    />
  </svg>
)

SvgParametria.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgParametria
