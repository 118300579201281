import React from 'react'
import { Breadcrumb } from 'common/atoms'
import { TableProvider } from 'services/providers/table-provider'
import { SalesTable } from '../../organisms/SalesTable/SalesTable'
import { SALES_URL } from 'common/utils/constants'

export const Sales = () => {
  return (
    <TableProvider>
      <Breadcrumb breadcrumbs={[{ title: 'Ventas', href: SALES_URL }]} />
      <SalesTable />
    </TableProvider>
  )
}
