import React from 'react'
import Styles from './error-boundary.module.scss'
import { CloseCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: ''
    }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={Styles.container}>
          <CloseCircleOutlined className={Styles.container__icon} />
          <div className={Styles.container__message}>
            <h1>Something went wrong! </h1>
            <h2>Error: {this.state.errorMessage}</h2>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

export default ErrorBoundary
