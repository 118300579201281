import React, { useEffect, useState } from 'react'
import { SALES_URL } from 'common/utils/constants'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from 'common/atoms'
import { SectionSimple } from 'common/templates'
import { SaleForm } from '../../organisms/SaleForm/SaleForm'
import { SectionWithTabs } from 'common/organisms'
import { UserForm } from '../../organisms/UserForm/UserForm'
import { ShippingForm } from '../../organisms/ShippingForm/ShippingForm'
import { LogisticForm } from '../../../../../common/components/shared/LogisticForm/LogisticForm'
import { DigitalExperienceForm } from '../../organisms/DigitalExperienceForm/DigitalExperienceForm'
import { useSaleDetail } from 'services/hooks/useSaleDetail'

export const SaleDetailTabs = () => {
  const { id } = useParams<{ id: string }>()
   const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data } = useSaleDetail(id || '', appId || '')
  const [nftAvailable, setNftAvailable] = useState<boolean>(false)

  useEffect(() => {
    if (data?.items) {
      const hasNftWithValues = data.items.some(item => item.nft && Object.keys(item.nft).length > 0);
      setNftAvailable(hasNftWithValues)
    }
  }, [data])

  const panelsTabs = [
    {
      label: `ORDEN`,
      key: '1',
      children: <SaleForm />
    },
    {
      label: `CLIENTE`,
      key: '2',
      children: <UserForm />
    },
    {
      label: `DOMICILIO Y FACTURACIÓN`,
      key: '3',
      children: <ShippingForm />
    },
    {
      label: `LOGÍSTICA`,
      key: '4',
      children: <LogisticForm />
    },
    ...(nftAvailable ? [{
      label: `EXPERIENCIA DIGITAL`,
      key: '5',
      children: <DigitalExperienceForm />
    }] : [])
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={[{ title: 'Ventas', href: SALES_URL }, { title: '#' + id }]} />
      <SectionSimple title="Detalle de orden" returnRoute={SALES_URL}>
        <SectionWithTabs fullWidthTabs={true} panels={panelsTabs} ></SectionWithTabs>
      </SectionSimple>
    </>
  )
} 
