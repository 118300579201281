import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from 'services/providers/user-context.jsx'
import Styles from './router.module.scss'
import PropTypes from 'prop-types'

export const PublicRoute = ({ children }) => {
  const { user } = useContext(UserContext)
  const isAuthenticated = !!user.accessToken
  return isAuthenticated ? <Navigate to="/" /> : <div className={Styles.publicRoutesContent}>{children}</div>
}

PublicRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
