import * as React from 'react'

const SvgGarbageCan = props => (
  <svg width="1em" height="1em" viewBox="0 0 13 13" fill="inherit" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m9.225 3.515-.298-.7c-.06-.142-.229-.237-.419-.237H.733c-.19 0-.358.095-.418.236l-.299.701c-.067.16.083.323.297.323h8.615c.214 0 .364-.164.297-.323ZM1.46 12.969c.033.302.289.53.593.53H7.14c.304 0 .56-.228.594-.53l.907-8.167H.552l.908 8.167Z" />
    <path d="M3.542 1.92c0-.236.18-.429.4-.429h1.357c.22 0 .4.193.4.43V3.5h.921V1.92C6.62 1.138 6.028.5 5.3.5H3.941c-.729 0-1.322.637-1.322 1.42V3.5h.922V1.92Z" />
  </svg>
)

export default SvgGarbageCan
