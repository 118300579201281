import { useQuery } from '@tanstack/react-query'
import { CACHE_TIME_24HOUR, TABLE_INITIAL_PAGE } from 'common/utils/constants'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IDropdownItem } from 'types/IDropdownItem'
import { IPaginatedResponse } from 'types/IPaginatedResponse'

export const useGetCountries = (useNameAsKey = false) => {
  const getCountriesFromApi = async (): Promise<IPaginatedResponse<any[]>> => {
    return ApiRequest(
      addQueryString(`${API_URL}${APIEndpoints.COUNTRIES}`, { page: TABLE_INITIAL_PAGE, size: 499 }),
      'GET'
    )
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }
  const getProcessedCountriesFilter = async (): Promise<IDropdownItem[]> => {
    return (await getCountriesFromApi()).items.map(item => ({
      key: useNameAsKey ? item.name : item.id,
      name: item.name
    }))
  }
  return useQuery(['countryList', useNameAsKey], getProcessedCountriesFilter, {
    cacheTime: CACHE_TIME_24HOUR,
    staleTime: CACHE_TIME_24HOUR
  })
}
