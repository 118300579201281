export const HOME_URL = '/'
export const PERMISSIONS_ERROR = '/no-permissions'
export const INVALID_URL = '/404'
export const LOGIN_URL = '/login'
export const SALES_URL = '/sales'
export const SALES_LIST_URL = '/sales-list'
export const PRODUCTS_LIST_URL = '/products'
export const PROFILE_URL = '/profile'

export const USER = 'user'
export const EXPIRED_SESSION = 'expired_session'
export const PAGE_SIZE = 20

export const TABLE_INITIAL_PAGE = 1
export const INITIAL_TABLE_FILTERS = {
  page: TABLE_INITIAL_PAGE,
  size: PAGE_SIZE
}

export const ROLE = {
  ROLE_VIEWER: 'ROLE_VIEWER',
  ROLE_ADMIN_MASTER: 'ROLE_ADMIN_MASTER',
  ROLE_ADMIN: 'ROLE_ADMIN'
}

export const PUBLIC_ROUTES = ['/', '/login', '/404', '/profile', '/dashboard']
export const ALL_ROUTES = [...PUBLIC_ROUTES, SALES_URL, SALES_LIST_URL, PRODUCTS_LIST_URL ]

export const DATE_FORMATS = {
  DMY: 'DD/MM/YYYY',
  MY: 'MM/YYYY',
  FULL_DASH: 'DD-MM-yyyy HH:mm:ss'
}

export const PERIOD_UNIT_VALUES = {
  MONTHS: 'months',
  DAYS: 'days'
}

export const CACHE_TIME_24HOUR = 86400000
