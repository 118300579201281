import * as React from 'react'

const SvgEditS = () => (
  <svg viewBox="0 0 19 19" width="1em" height="1em" fill="inherit">
    <path
      d="M17.078 1.422a3.15 3.15 0 0 1 0 4.456L4.607 18.347a.525.525 0 0 1-.37.153H1.05C.47 18.5 0 18.03 0 17.45v-3.187c0-.14.055-.273.153-.372l12.47-12.469a3.15 3.15 0 0 1 4.455 0ZM18.288 17c.141 0 .212.067.212.2v1.1c0 .133-.07.2-.212.2H9.712c-.141 0-.212-.067-.212-.2v-1.1c0-.133.07-.2.212-.2h8.576ZM11.586 4.686l-9.95 9.95a.21.21 0 0 0-.06.149v1.93c0 .117.093.21.21.21h1.93a.21.21 0 0 0 .148-.061l9.95-9.95-2.228-2.228Zm6.692 8.814c.148 0 .222.067.222.2v1.1c0 .133-.074.2-.222.2h-4.556c-.148 0-.222-.067-.222-.2v-1.1c0-.133.074-.2.222-.2h4.556ZM13.804 2.473l-.068.063-1.05 1.05 2.228 2.228 1.05-1.05c.59-.59.618-1.537.063-2.16l-.063-.067v-.001a1.575 1.575 0 0 0-2.16-.063Z"
      fill="inherit"
    />
  </svg>
)

export default SvgEditS
