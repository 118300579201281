import React from 'react'
import { Error404 } from 'common/organisms'
import { Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Sales } from './components/pages/Sales/Sales'
import { SaleDetailTabs } from './components/pages/SaleDetailTabs/SaleDetailTabs'

export const SalesRoutes = ({ path = 'sales' }) => {
  return (
    <Routes>
      <Route path={path}>
        <Route index element={<Sales />} />
        <Route path=":id" element={<SaleDetailTabs />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}

SalesRoutes.propTypes = {
  path: PropTypes.string
}
