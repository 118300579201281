import React from 'react'
import { Breadcrumb } from 'common/atoms'
import { TableProvider } from 'services/providers/table-provider'
import { ProductsListTable } from '../../organisms/ProductsTable/ProductsTable'
import { PRODUCTS_LIST_URL } from 'common/utils/constants'

export const ProductsList = () => {
  return (
    <TableProvider>
      <Breadcrumb breadcrumbs={[{ title: 'Productos', href: PRODUCTS_LIST_URL }]} />
      <ProductsListTable />
    </TableProvider>
  )
}
