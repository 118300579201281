import { Navigate } from 'react-router-dom'
import React, { useContext } from 'react'
import { UserContext } from 'services/providers/user-context'
import { IUser } from 'types/IUser'

export const HomeRoute = () => {
  const { user } = useContext(UserContext) as { user: IUser | null };
  const { menus } = user?.secondaryNavbar
  return (
    <>
      <Navigate to={menus[0]?.url || '/profile'} replace />
    </>
  )
}
