import * as React from 'react'
import PropTypes from 'prop-types'

const SvgResale = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    transform="rotate(-180)"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M15.493 6.697H1.666l5.052-4.89a.5.5 0 0 0 .009-.713.52.52 0 0 0-.712-.008L.152 6.763a.5.5 0 0 0-.009.707l5.714 5.82a.505.505 0 0 0 .864-.341.498.498 0 0 0-.143-.356l-4.81-4.9h13.729a.505.505 0 0 0 .503-.5.499.499 0 0 0-.503-.5"
      fill="currentColor"
    />
  </svg>
)

SvgResale.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgResale
