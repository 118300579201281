import React from 'react'

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
    <path
      d="M5.49958 9.85718L5.14603 10.2107L5.49958 10.5643L5.85313 10.2107L5.49958 9.85718ZM5.99958 1.28575C5.99958 1.00961 5.77572 0.78575 5.49958 0.78575C5.22344 0.78575 4.99958 1.00961 4.99958 1.28575L5.99958 1.28575ZM0.860314 5.92502L5.14603 10.2107L5.85313 9.50362L1.56742 5.21791L0.860314 5.92502ZM5.85313 10.2107L10.1388 5.92502L9.43174 5.21791L5.14603 9.50362L5.85313 10.2107ZM5.99958 9.85718L5.99958 1.28575L4.99958 1.28575L4.99958 9.85718L5.99958 9.85718Z"
      fill="currentColor"
    />
    <path d="M0.5 12.7144H10.5" stroke="currentColor" />
  </svg>
)

export default DownloadIcon
