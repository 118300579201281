import React, { useEffect } from 'react'
import { Form } from 'antd'
import { CustomInput, CustomFormItem } from 'common/molecules'
import Styles from './sale-form.module.scss'
import { SalesPaymentStatusName } from 'services/hooks/usePaymentStatus'
import { DATE_FORMATS, HOME_URL } from 'common/utils/constants'
import { getLocalString } from 'common/utils/func-helpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { Loader } from 'common/atoms'

export const SaleForm = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data, isLoading: isLoading, isFetched, error } = useSaleDetail(id || '', appId || '')
  const [form] = Form.useForm()

  useEffect(() => {
    if (error && isFetched) {
      navigate(HOME_URL)
    }
    form.resetFields()
    form.setFieldsValue({ customPaid: SalesPaymentStatusName[form.getFieldValue('status')] || form.getFieldValue('status') })
    form.setFieldsValue({ customCreationDate: getLocalString(form.getFieldValue('createdAt'), DATE_FORMATS.FULL_DASH) })
    form.setFieldsValue({ customUpdateDate: getLocalString(form.getFieldValue('updatedAt'), DATE_FORMATS.FULL_DASH) })
  }, [data, error])



  return (
    <>
      <Loader loading={isLoading}>
        <div>
          <Form form={form} layout="vertical" className={Styles.form} initialValues={data}>
            <CustomFormItem name="number" label="Número de orden" >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

            <CustomFormItem name="channel" label="Canal de ventas"  >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

            <CustomFormItem name="customPaid" label="Estado de orden">
              <CustomInput
                paddingBlock="0.5em"
                disabled
                value={4}
                placeholder="--"
              />
            </CustomFormItem>

            <CustomFormItem name="customCreationDate" label="Fecha de creación"  >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

            <CustomFormItem name="customUpdateDate" label="Fecha de ultima actualización"  >
              <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
            </CustomFormItem>

          </Form>

        </div>
      </Loader>
    </>
  )
}
