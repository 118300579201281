import React, { useContext } from 'react'
import Styles from './profile-view.module.scss'
import { SectionSimple } from 'common/templates'
import { UserContext } from 'services/providers/user-context'

export const Profile = () => {
  const { user } = useContext(UserContext)
  return (
    <SectionSimple title="PERFIL">
      <div className={Styles.title}>
        <span>INFORMACIÓN DE USUARIO</span>
      </div>
      <div className={Styles.data}>
        <div className={Styles.data__item}>
          <p className={Styles.data__item__title}>Nombre</p>
          <span>{user?.firstName}</span>
        </div>
        <div className={Styles.data__item}>
          <p className={Styles.data__item__title}>Apellido</p>
          <span>{user?.lastName}</span>
        </div>
        <div className={Styles.data__item}>
          <p className={Styles.data__item__title}>Email</p>
          <span>{user?.email}</span>
        </div>
      </div>
    </SectionSimple>
  )
}
