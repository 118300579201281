import React from 'react'
import { EyeOutlined } from '@ant-design/icons'
import TextWithClipboardButton from 'common/components/molecules/TextWithClipboardButton/TextWithClipboardButton'
import { CustomButton } from 'common/atoms'
import { getLocalString } from 'common/utils/func-helpers'
import { DATE_FORMATS, PAGE_SIZE, TABLE_INITIAL_PAGE } from 'common/utils/constants'
import { IOrder } from 'types/IOrderSale'

export const INITIAL_SALES_TABLE_FILTERS = {
  page: TABLE_INITIAL_PAGE,
  size: PAGE_SIZE,
  sort: 'created_at,desc'
}

export const SalesTableColumns = (viewDetail: any) => [
  {
    title: '# Orden',
    dataIndex: 'number',
    key: '1',
    width: 60,
    render: (number: any) => <TextWithClipboardButton text={number} abreviate={false}/>
  },
  {
    title: 'Nombre completo',
    dataIndex: 'buyerFullName',
    key: '2',
    width: 60
  },
  {
    title: 'Email de compra',
    dataIndex: 'buyerEmailAddress',
    key: '3',
    width: 80
  },
  {
    title: 'Estado de usuario',
    dataIndex: 'buyerStatus',
    key: '4',
    width: 35,
    render: (buyerStatus: boolean) => (buyerStatus ? 'Activo' : 'Inactivo')
  },
  {
    title: 'CÓDIGO DE ACTIVACIÓN',
    dataIndex: 'activationCode',
    key: '5',
    width: 35,
    render: (activationCode: string) => {
      return <TextWithClipboardButton text={activationCode} abreviate={false} />
    }
  },
  {
    title: 'Pais',
    dataIndex: 'country',
    key: '6',
    width: 40
  },
  {
    title: 'Fecha compra',
    width: 40,
    dataIndex: 'createdAt',
    key: '8',
    render: (createdAt: number) => getLocalString(createdAt, DATE_FORMATS.DMY),
    sorter: true,
    sortFieldName: 'created_at'
  },
  {
    title: 'Fecha update',
    width: 40,
    dataIndex: 'updatedAt',
    key: '9',
    render: (updatedAt: number) => getLocalString(updatedAt, DATE_FORMATS.DMY),
    sorter: true,
    sortFieldName: 'updated_at'
  },
  {
    title: 'Acciones',
    dataIndex: 'number',
    width: 25,
    key: '10',
    render: (number: number, item: IOrder) => (
      <CustomButton
        type="link"
        inheritColor
        icon={
          <EyeOutlined
            style={{ fontSize: '1.2rem' }}
            onClick={() => viewDetail(number, item.appId)}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        children={undefined}
      />
    )
  }
]
