import { useQuery } from '@tanstack/react-query'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IOrder } from 'types/IOrderSale'
 import { IPaginatedResponse } from 'types/IPaginatedResponse'

export const useSales = (filter: any) => {
  const getSalesFromApi = async (): Promise<IPaginatedResponse<IOrder[]>> => {
    return ApiRequest(addQueryString(`${API_URL}${APIEndpoints.SALES_LIST}`, filter), 'GET')
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }

  return useQuery(['orderSales', filter], getSalesFromApi, {
    staleTime: 0,
    cacheTime: 0
  })
}
