import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Styles from './custom-button.module.scss'
import { Button } from 'antd'

export const CustomButton = ({ theme = '', type = '', className = '', children, inheritColor, ...rest }) => {
  return (
    <Button
      {...{ type }}
      className={classnames(
        className,
        Styles.button,
        Styles[`--${type}`],
        Styles[`--${theme}`],
        inheritColor && Styles[`--inheritColor`]
      )}
      {...rest}
    >
      {children}
    </Button>
  )
}

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inheritColor: PropTypes.bool,
  loading: PropTypes.bool,
  loadingColor: PropTypes.oneOf(['primary', 'secondary']),
  rounded: PropTypes.bool,
  theme: PropTypes.oneOf(['ghostRounded', 'whiteGhost', 'primary', 'white']),
  type: PropTypes.oneOf(['primary', 'dashed', 'button', 'link', 'ghost'])
}
