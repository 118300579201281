export const REQUIRED_FIELD = 'Campo obligatorio'
export const NUMERIC_FIELD = 'Campo numerico'
export const NUMERIC_FIELD_WITH_SPECIAL_CHARS = 'Campo numerico con caracteres especiales'
export const POSITIVE_NUMERIC_FIELD = 'Campo numerico mayor a 0 (cero)'
export const PASSWORD = 'Contraseña invalida'
export const PASSWORD_NOT_MATCH = '¡Las dos contraseñas que ingresó no coinciden!'
export const EMAIL_NOT_MATCH = '¡Los emails del Usuario no coinciden!'
export const AVAILABLE_STOCK_MUST_BE_LESS = 'El stock disponible debe ser menor o igual que el stock real'
export const EMAIL = 'E-mail invalido'
export const PHONE = 'Invalid phone'
export const EXPIRED_SESSION_MESSAGE = 'Su sesión ha caducado. Por favor Iniciar sesión.'
export const LIMIT_FOR_MAX_VALUE = 'Este campo no puede ser mayor que :max'
export const REQUIRED_EMAIL_MESSAGE = 'Por favor complete el mail'
export const REQUIRED_PASSWORD_MESSAGE = 'Por favor complete la contraseña'
export const REQUIRED_CONFIRM_PASSWORD_MESSAGE = 'Por favor confirme la contraseña'
export const REQUIRED_NAME_MESSAGE = 'Por favor complete el nombre'
export const REQUIRED_SURNAME_MESSAGE = 'Por favor complete el apellido'
export const REQUIRED_REGION_MESSAGE = 'Por favor complete al menos una region'
export const REQUIRED_USER_TYPE_MESSAGE = 'Por favor seleccione tipo de usuario'
export const REQUIRED_COUNTRY_MESSAGE = 'Por favor ingrese al menos un país'
export const REQUIRED_NOTIFICATION_TYPE_MESSAGE = 'Por favor, ingrese el tipo de notificación'
export const REQUIRED_NOTIFICATION_TITLE_MESSAGE = 'Por favor, ingrese el título de la notificación'
export const REQUIRED_NOTIFICATION_MESSAGE_MESSAGE = 'Por favor, ingrese el mensaje de la notificación'
export const MAX_CHARACTERS_MESSAGE = max => `El campo no puede tener más de ${max} caracteres`
