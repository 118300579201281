import React from 'react'
import { CopyOutlined } from '@ant-design/icons'
import Styles from './text-with-clipboard-button.module.scss'
import { getEllipsisString } from 'common/utils/func-helpers'
import { CustomButton } from 'common/atoms'
import { message } from 'antd'

interface Props {
  text: string
  abreviate?: boolean
}

const TextWithClipboardButton = ({ text, abreviate = true }: Props) => {
  return (
    <div className={Styles.container}>
      <div className={`${abreviate ? Styles.container__text : Styles.container__text__auto}`}>
        {abreviate ? getEllipsisString(text, 6, 3, 3) : text}
      </div>
      {text && (
        <CustomButton
          onClick={() => {
            message.success('Texto copiado')
            navigator.clipboard.writeText(text)
          }}
          type="link"
          icon={<CopyOutlined onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />}
          className={Styles.container__copyButton}
          theme={undefined}
          children={undefined}
          inheritColor={undefined}
        />
      )}
    </div>
  )
}
export default TextWithClipboardButton
