import React from 'react'

import { CustomTable } from 'common/organisms'
import { currencyFormat } from 'common/utils/func-helpers'
import { IOrderDetail } from 'types/IOrderSale'
import Styles from './styles.module.scss'

interface IProps {
  order: IOrderDetail
}

export const SaleDetailTable = (props: IProps) => {
  const SaleDetailTableColumns = () => [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      width: 50
    },
    {
      title: 'Sku',
      dataIndex: 'sku',
      key: '2',
      width: 70
    },
    {
      title: 'Producto',
      dataIndex: 'name',
      key: '3',
      width: 150
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: '4',
      width: 50,
      render: (serie: string) => <span className="text-[#5584FF]">{serie}</span>
    },
    {
      title: 'cantidad',
      dataIndex: 'quantity',
      key: '5',
      width: 50,
      render: (quantity: number) => <span className="text-[#5584FF]">{quantity}</span>
    },

    {
      title: 'precio',
      dataIndex: 'price',
      key: '6',
      width: 50,
      render: (price: number) => <span className="text-[#5584FF]">{currencyFormat(price)}</span>
    },
  ]
  const sumOfDiscounts = () => {
    return props.order.discounts.reduce((total, item) => total + item.value, 0);
  }

  const renderFooter = () => (
    <div className="flex items-end flex-col ">
      <div className="min-w-[150px]">
        <div className={Styles.totalItemDetail}>
          <span className=" font-bold text-[16px]">Total</span>{' '}
          <span className="text-[#5584FF]">{props.order.total ? currencyFormat(props.order.total) : '--'}</span>
        </div>
        <div className={Styles.totalItemDetail}>
          <span>Subtotal</span>{' '}
          <span className="text-[#5584FF]">{props.order.subtotal ? currencyFormat(props.order.subtotal) : '--'}</span>
        </div>
        <div className={Styles.totalItemDetail}>
          <span>Subtotal de envío</span>{' '}
          <span className="text-[#5584FF]">
            {props.order.rates.shipping ? currencyFormat(props.order.rates.shipping) : '--'}
          </span>
        </div>
        <div className={Styles.totalItemDetail}>
          <span>Subtotal de aduana</span>{' '}
          <span className="text-[#5584FF]">
            {props.order.rates.customs ? currencyFormat(props.order.rates.customs) : '--'}
          </span>
        </div>
        <div className={Styles.totalItemDetail}>
          <span>Redondeo</span>{' '}
          <span className="text-[#5584FF]">
            {props.order.rounding ? currencyFormat(props.order.rounding) : '--'}
          </span>
        </div>

        <div className={Styles.totalItemDetail}>
          <span>Descuentos</span>
          <span className="text-[#5584FF]">
            {sumOfDiscounts() ? currencyFormat(sumOfDiscounts()) : '--'}
          </span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <CustomTable
        loading={false}
        withPagination={false}
        columns={SaleDetailTableColumns()}
        data={props.order.items}
        totalElements={0}
        footer={renderFooter}
      />
    </>
  )
}
