import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { USER } from 'common/utils/constants'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      localStorage.setItem(USER, JSON.stringify(action.payload))
      return action.payload
    case 'DELETE_USER':
      localStorage.removeItem(USER)
      return {}
    default:
      return state
  }
}

const localState = JSON.parse(localStorage.getItem(USER))

const UserContext = createContext({})

const UserProvider = ({ children, initialState = {} }) => {
  const [user, setUser] = useReducer(reducer, localState || initialState)

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object
}

export { UserContext, UserProvider }
