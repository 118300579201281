import React from 'react'
import { SALES_LIST_URL } from 'common/utils/constants'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from 'common/atoms'
import { SectionSimple } from 'common/templates'
import { SaleForm } from '../../organisms/SaleForm/SaleForm'
import { SectionWithTabs } from 'common/organisms'
import { UserForm } from '../../organisms/UserForm/UserForm'
import { ShippingForm } from '../../organisms/ShippingForm/ShippingForm'
import { LogisticForm } from 'common/components/shared/LogisticForm/LogisticForm'
 
export const SaleDetailTabs = () => {
  const { id } = useParams<{ id: string }>()

  const panelsTabs = [
    {
      label: `ORDEN`,
      key: '1',
      children: <SaleForm />
    },
    {
      label: `CLIENTE`,
      key: '2',
      children: <UserForm />
    },
    {
      label: `DOMICILIO`,
      key: '3',
      children: <ShippingForm />
    },
    {
      label: `LOGÍSTICA`,
      key: '4',
      children: <LogisticForm />
    }
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={[{ title: 'Ventas', href: SALES_LIST_URL }, { title: '#' + id }]} />
      <SectionSimple title="Detalle de orden" returnRoute={SALES_LIST_URL}>
        <SectionWithTabs fullWidthTabs={true} panels={panelsTabs} ></SectionWithTabs>
      </SectionSimple>
    </>
  )
} 
