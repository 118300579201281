import * as React from 'react'
import PropTypes from 'prop-types'

const SvgDashboard = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.055.968H.477A.479.479 0 0 0 0 1.442v7.53a.473.473 0 0 0 .477.474h7.578a.48.48 0 0 0 .473-.474v-7.53a.473.473 0 0 0-.473-.474ZM7.73 8.65H.8V1.764H7.73V8.65ZM17.658.968H10.08a.48.48 0 0 0-.478.474v7.53a.473.473 0 0 0 .478.474h7.578a.48.48 0 0 0 .477-.474v-7.53a.473.473 0 0 0-.477-.474Zm-.325 7.681h-6.928V1.764h6.928V8.65ZM8.055 10.377H.477A.479.479 0 0 0 0 10.85v7.53a.472.472 0 0 0 .477.474h7.578a.48.48 0 0 0 .477-.474v-7.53a.473.473 0 0 0-.477-.473Zm-.325 7.68H.8v-6.884H7.73v6.884ZM17.658 10.377H10.08a.48.48 0 0 0-.478.473v7.53a.474.474 0 0 0 .478.474h7.578a.48.48 0 0 0 .477-.474v-7.53a.473.473 0 0 0-.477-.473Zm-.325 7.68h-6.928v-6.884h6.928v6.884Z"
      fill="currentColor"
    />
  </svg>
)

SvgDashboard.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgDashboard
