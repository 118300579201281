import React, { useEffect, useState } from 'react'
import { IOrderDetail, IOrderDetailItem, IOrderDetailItemLogisticItem } from 'types/IOrderSale'
import { Card, Form, Timeline } from 'antd'
import Styles from './styles.module.scss'
import { CustomFormItem, CustomInput } from 'common/molecules'
import { getLocalString, isObjectEmpty } from 'common/utils/func-helpers'
import { DATE_FORMATS } from 'common/utils/constants'
import { AimOutlined } from '@ant-design/icons'

interface IProps {
  order: IOrderDetail
  orderItem: IOrderDetailItem
}

export const OrderItemLogisticDetails = (_props: IProps) => {
  const [form] = Form.useForm()
  const [timelineItems, setTimelineItems] = useState<IOrderDetailItemLogisticItem[]>([])

  useEffect(() => {
    let sortedTimelineItems = _props.orderItem.logistics?.sort((a, b) => {
      const dateA = new Date(a.eventDatetime);
      const dateB = new Date(b.eventDatetime);
      return dateB.getTime() - dateA.getTime();
    })
    setTimelineItems(sortedTimelineItems)
    form.setFieldsValue({ lastUpdate: getLocalString(_props.order.logistic?.eventDatetime, DATE_FORMATS.FULL_DASH) })
    form.setFieldsValue({ carrier: sortedTimelineItems[0]?.trackingCompany })
    form.setFieldsValue({ clientLastState: sortedTimelineItems[0]?.name })
    form.setFieldsValue({ trackingCode: sortedTimelineItems[0]?.trackingNumber })
  }, [])

  const clickHandler = () => {
    if (!timelineItems[0]?.trackingUrl) return
    window.open(timelineItems[0]?.trackingUrl, '_blank');
  }

  return (
    <div className='flex flex-col w-full gap-[16px]'  >
      <Card size="small" title="Domicilio de entrega" style={{ backgroundColor: 'rgb(230 230 230)' }} headStyle={{ fontWeight: 700, fontSize: '16px' }}>
        <Form form={form} layout="vertical" className={Styles.form} initialValues={_props?.orderItem}>

          <CustomFormItem name="clientLastState" label="Estado de envío - Cliente" >
            <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
          </CustomFormItem>

          <CustomFormItem name="clientLastState" label="Estado de envío -  Carrier"  >
            <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
          </CustomFormItem>

          <CustomFormItem name="lastUpdate" label="Última actualización"  >
            <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
          </CustomFormItem>

          <CustomFormItem name="carrier" label="Carrier"  >
            <CustomInput paddingBlock="0.5em" disabled placeholder="--" />
          </CustomFormItem>

          <CustomFormItem name="trackingCode" label="Código de seguimiento" className="!cursor-pointer " onClick={() => clickHandler}>
            <CustomInput
              paddingBlock="0.5em"
              disabled
              placeholder="--"
              suffix={
                <div onClick={() => clickHandler()} className='cursor-pointer hover:underline'>Abrir<AimOutlined
                  className='ml-1'
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                /></div>
              }
            />
          </CustomFormItem>
        </Form>
      </Card>

      {(timelineItems.length && !isObjectEmpty(timelineItems)) ? <>
        <p className="font-bold">Historial de eventos</p>

        <Timeline>
          {timelineItems.map((item, index) => {
            if (!item.name || !item.eventDatetime) return
            return <Timeline.Item key={index}>Estado de envío actualizado a {item.name}, {getLocalString(item.eventDatetime, DATE_FORMATS.FULL_DASH)}</Timeline.Item>
          })}
        </Timeline>
      </> : null}
    </div>

  )
}
