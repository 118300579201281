import React, { useState } from 'react'
import { message, Modal } from 'antd'
import { processedErrorMessage } from 'services/api-calls/helpers'
import PropTypes from 'prop-types'
import { CustomButton } from 'common/atoms'
import Styles from './download-csv.module.scss'

export const DownloadCSV = ({ apiCall }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onExportData = async () => {
    try {
      setIsLoading(true)
      await apiCall()
      Modal.success({
        title: 'Reporte descargado con éxito!',
        content: (
          <div>
            <p style={{ margin: 0 }}>Puedes visualizar el archivo descargado en tu carpeta de descargas</p>
          </div>
        ),
        centered: true,
        okText: 'Entendido'
      })
    } catch (error) {
      console.error(error)
      const errorMessage = processedErrorMessage(error)
      message.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomButton
      onClick={onExportData}
      htmlType="submit"
      type="primary"
      loading={isLoading}
      className={Styles.button}
      aria-label="Descargar reporte"
    >
      DESCARGAR REPORTE
    </CustomButton>
  )
}
DownloadCSV.propTypes = {
  apiCall: PropTypes.func
}
