import { Form } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Styles from './custom-form-item.module.scss'

export const CustomFormItem = ({ children = <></>, className = '', hideError = undefined, name, ...rest }) => {
  const classnames = [Styles.customFormItem, className, hideError && Styles['--hideError']]
  return (
    <Form.Item className={classNames(...classnames)} name={name} {...rest}>
      {children}
    </Form.Item>
  )
}

CustomFormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hideError: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
