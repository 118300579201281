import { Modal } from 'antd'
import { CloseCircleFilled, ExclamationCircleFilled, CheckCircleFilled, WarningOutlined } from '@ant-design/icons'
import Styles from './modals.module.scss'
import classNames from 'classnames'

export const openErrorModal = ({
  title = '',
  contentMessage = '',
  highlightedNote = '',
  okText = 'Entendido',
  variant = 'minimal'
}) => {
  const modalTypes = {
    minimal: {
      title,
      content: (
        <div>
          <p style={{ margin: 0 }}>{contentMessage}</p>
        </div>
      ),
      okText,
      centered: true,
      onOk: Modal.destroyAll
    },
    complete: {
      className: Styles.container,
      bodyStyle: { textAlign: 'center' },
      icon: false,
      title: (
        <>
          <CloseCircleFilled className={classNames(Styles.container__icon, Styles['--danger'])} />
          <h3 className={Styles.container__title}>{title}</h3>
        </>
      ),
      content: (
        <>
          <p className={Styles.container__contentMessage}>{contentMessage}</p>
          <p className={Styles.container__highlightedNote}>{highlightedNote}</p>
        </>
      ),
      centered: true,
      okText,
      onOk: Modal.destroyAll
    }
  }

  return Modal.error(modalTypes[variant])
}

export const openConfirmationModal = ({
  handleConfirm,
  handleCancel = () => {},
  title = '',
  contentMessage = '',
  highlightedNote = '',
  type = 'alert',
  variant
}) => {
  const ModalTypes = {
    minimal: {
      title,
      content: (
        <div>
          <p style={{ margin: 0 }}>{contentMessage}</p>
        </div>
      ),
      okText: 'Sí',
      cancelText: 'No',
      centered: true,
      onOk: handleConfirm
    },
    complete: {
      className: Styles.container,
      bodyStyle: { textAlign: 'center' },
      icon: false,
      title: (
        <>
          {type === 'alert' && (
            <ExclamationCircleFilled className={classNames(Styles.container__icon, Styles['--alert'])} />
          )}
          {type === 'danger' && (
            <CloseCircleFilled className={classNames(Styles.container__icon, Styles['--danger'])} />
          )}
          <h3>{title}</h3>
        </>
      ),
      content: (
        <>
          <p className={Styles.container__contentMessage}>{contentMessage}</p>
          <p className={Styles.container__highlightedNote}>{highlightedNote}</p>
        </>
      ),
      centered: true,
      onOk: () => handleConfirm(),
      cancelText: 'Cancelar',
      okText: 'Confirmar',
      onCancel: () => handleCancel()
    }
  }

  return Modal.confirm(ModalTypes[variant])
}

export const openSuccessModal = ({
  title = '',
  contentMessage = '',
  variant,
  highlightedNote = '',
  extraContentMessage,
  withHighlightedNoteIcon = false,
  onOk
}) => {
  const ModalTypes = {
    minimal: {
      title,
      content: (
        <div>
          <p style={{ margin: 0 }}>{contentMessage}</p>
        </div>
      ),
      okText: 'Entendido',
      centered: true
    },
    complete: {
      className: Styles.container,
      bodyStyle: { textAlign: 'center' },
      icon: false,
      title: (
        <>
          <CheckCircleFilled className={classNames(Styles.container__icon, Styles['--success'])} />
          <h3 className={Styles.container__title}>{title}</h3>
        </>
      ),
      content: (
        <>
          <p className={Styles.container__contentMessage}>{contentMessage}</p>
          {extraContentMessage}
          <p className={Styles.container__highlightedNote}>
            {withHighlightedNoteIcon && (
              <span className={Styles.container__highlightedNote__icon}>
                <WarningOutlined />
              </span>
            )}
            {highlightedNote}
          </p>
        </>
      ),
      centered: true,
      okText: 'Aceptar',
      onOk
    }
  }

  return Modal.success(ModalTypes[variant])
}
