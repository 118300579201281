import React, { ReactNode, useState } from 'react'
import Styles from './section-with-table.module.scss'
import { CustomButton, TitlePage } from 'common/atoms'
import classnames from 'classnames'
import { DeleteFilled, ReloadOutlined } from '@ant-design/icons'

interface SectionWithTableProps {
  controls?: ReactNode | ReactNode[]
  footer?: ReactNode | ReactNode[]
  table?: ReactNode | ReactNode[]
  title?: string
  showClear?: boolean
  showRefresh?: boolean
  onClear?: () => void
  onRefresh?: () => void
}

const SectionWithTable = ({ controls, title, table, footer, showClear = false, showRefresh = false, onClear, onRefresh }: SectionWithTableProps) => {
  const headerClassnames = classnames(Styles.container__header)
  const controlsClassnames = classnames(Styles.container__header__controls)
  const [shouldRenderFilters, setShouldRenderFilters] = useState(true)

  const onClearFiltersHandler = async () => {
    if (onClear) {
      await setShouldRenderFilters(false)
      await setShouldRenderFilters(true)
      onClear()
    }
  }

  const onRefreshHandler = async () => {
    if (onRefresh) {
      onRefresh()
    }
  }

  return (
    <div className={Styles.container}>
      <div className={headerClassnames}>
        <TitlePage text={title} className={Styles.container__header__title} />
        <div className={controlsClassnames}>
          {shouldRenderFilters && controls}
          {showClear && (
            <div
            className="flex items-center justify-center"
           >
            <CustomButton
              icon={<DeleteFilled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'grey' }} />}
              onClick={onClearFiltersHandler}
              className={''}
              ghost
              type="button"
              inheritColor={undefined}>
              LIMPIAR
            </CustomButton>
          </div>

          )}
          {showRefresh && (
            <div
              className="flex items-center justify-center"
             >
              <CustomButton
                icon={<ReloadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'green' }} />}
                onClick={onRefreshHandler}
                className={''}
                ghost
                type="button"
                inheritColor={undefined}>
                ACTUALIZAR
              </CustomButton>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.container__content}>{table}</div>
      <div className={Styles.container__footer}>{footer}</div>
    </div>
  )
}

export default SectionWithTable
