import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Table as AntTable } from 'antd'
import Styles from './custom-table.module.scss'
import { TableContext } from 'services/providers/table-provider'
import classNames from 'classnames'

const { useBreakpoint } = Grid

export const CustomTable = ({
  columns = [],
  data = [],
  loading = true,
  showSizeChanger = true,
  highlightHeader = true,
  totalElements,
  withPagination,
  emptyContent = null,
  theme = undefined,
  rowClassName = '',
  headerRowClassName = '',
  defaultSort = '',
  ...rest
}) => {
  const screens = useBreakpoint()

  const { currentPage, setCurrentPage, setFilter, filter, cleanFilter, pageSize, setCurrentPageSize } =
    useContext(TableContext)
  const handlePaginationChange = pagination => {
    setFilter({ ...filter, ...pagination })
  }

  const handleTableChange = (pagination, sorter) => {
    setCurrentPage(pagination.current)
    setCurrentPageSize(pagination.pageSize)

    const newFilterObj = {
      size: pagination.pageSize,
      page: pagination.current
    }

    if (sorter.order) {
      const direction = sorter.order === 'descend' ? 'desc' : 'asc'
      const sortQuery = `${sorter.column.sortFieldName},${direction}`
      newFilterObj.sort = sortQuery
    } else if (defaultSort) {
      newFilterObj.sort = defaultSort
    }

    handlePaginationChange(newFilterObj)
  }

  useEffect(() => {
    cleanFilter?.()
  }, [])

  return (
    <>
      {data.length === 0 && emptyContent && !loading && emptyContent}

      {(data.length !== 0 || !emptyContent || loading) && (
        <AntTable
          className={Styles.table}
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={
            withPagination
              ? {
                  pageSize,
                  total: totalElements,
                  showSizeChanger: showSizeChanger,
                  current: currentPage,
                  className: Styles.pagination,
                  size: screens.xxl ? 'default' : 'small'
                }
              : false
          }
          scroll={{ y: 600 }}
          rowClassName={classNames(Styles.bodyRow, { [Styles['--white']]: theme === 'white' }, rowClassName)}
          onHeaderRow={() => ({
            className: classNames(
              { [Styles.headerRow]: highlightHeader },
              { [Styles['--white']]: theme === 'white' },
              headerRowClassName
            )
          })}
          sortDirections={['descend', 'ascend']}
          onChange={(pagination, filter, sorter) => {
            handleTableChange(pagination, sorter)
          }}
          {...rest}
        />
      )}
    </>
  )
}

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSort: PropTypes.string,
  emptyContent: PropTypes.node,
  headerRowClassName: PropTypes.string,
  highlightHeader: PropTypes.bool,
  initialPageSize: PropTypes.number,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  rowClassName: PropTypes.string,
  showSizeChanger: PropTypes.bool,
  theme: PropTypes.string,
  totalElements: PropTypes.number,
  withPagination: PropTypes.bool
}
