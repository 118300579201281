import React from 'react'
import { CustomTable } from 'common/organisms'
import TextWithClipboardButton from 'common/components/molecules/TextWithClipboardButton/TextWithClipboardButton'
import { getLocalString } from 'common/utils/func-helpers'
import { DATE_FORMATS } from 'common/utils/constants'
import { useParams } from 'react-router-dom'
import { useSaleDetail } from 'services/hooks/useSaleDetail'
import { INFT } from 'types/IOrderSale'

export const DigitalExperienceQR = () => {
  const { id } = useParams<{ id: string }>()
  const searchParams = new URLSearchParams(location.search)
  const appId = searchParams.get('appId')
  const { data: data, isLoading: isLoading } = useSaleDetail(id || '', appId || '')

  const TableColumns = () => [
    {
      title: 'Item',
      dataIndex: 'id',
      key: '1',
      width: 30
    },
    {
      title: 'Código QR',
      dataIndex: 'nft',
      key: '2',
      width: 40,
      align: 'left',
      render: (nft: INFT) => <div className='w-fit'> <TextWithClipboardButton text={nft.qr} abreviate={false} /></div>
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: '3',
      width: 40
    },
    {
      title: 'Fecha de Activación',
      width: 40,
      dataIndex: 'nft',
      key: '4',
      render: (nft: INFT) => getLocalString(nft.activationDate, DATE_FORMATS.DMY),
    }
  ]

  return (
    <div>
      <CustomTable
        loading={isLoading}
        withPagination={false}
        columns={TableColumns()}
        data={data?.items.filter(item => item.nft) || []}
        totalElements={data?.items.filter(item => item.nft) || []}
      />
    </div>
  )
}
