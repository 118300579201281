import { useQuery } from '@tanstack/react-query'
import { CACHE_TIME_24HOUR, TABLE_INITIAL_PAGE } from 'common/utils/constants'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IDropdownItem } from 'types/IDropdownItem'
import { IPaginatedResponse } from 'types/IPaginatedResponse'

export const useGetRegions = (useNameAsKey = false) => {
  const getRegionsFromApi = async (): Promise<IPaginatedResponse<any[]>> => {
    return ApiRequest(
      addQueryString(`${API_URL}${APIEndpoints.REGIONS}`, { page: TABLE_INITIAL_PAGE, size: 500 }),
      'GET'
    )
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }
  const getProcessedRegionsFilter = async (): Promise<IDropdownItem[]> => {
    return (await getRegionsFromApi()).items.map(region => ({
      key: useNameAsKey ? region.name : region.id,
      name: region.name
    }))
  }
  return useQuery(['regionsList', useNameAsKey], getProcessedRegionsFilter, {
    cacheTime: CACHE_TIME_24HOUR,
    staleTime: CACHE_TIME_24HOUR
  })
}
