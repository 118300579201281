import React, { useState } from 'react'
import Styles from './input-search.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { CustomButton } from 'common/atoms'
import { SearchOutlined } from '@ant-design/icons'

export const InputSearch = ({ className = '', containerClassName, onSearch, ...rest }) => {
  const [inputValue, setInputValue] = useState('')
  return (
    <div className={classnames(Styles.inputContainer, containerClassName)}>
      <Input
        className={classnames(Styles.inputContainer__input, className)}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            onSearch(inputValue.trim())
          }
        }}
        {...rest}
      />
      <CustomButton className={Styles.inputContainer__button} onClick={() => onSearch(inputValue.trim())}>
        <SearchOutlined />
      </CustomButton>
    </div>
  )
}

InputSearch.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onSearch: PropTypes.func
}
