import { useQuery } from '@tanstack/react-query'
import { CACHE_TIME_24HOUR } from 'common/utils/constants'
import ApiRequest from 'common/utils/httpInterceptor'
import { API_URL } from 'config/config'
import { APIEndpoints } from 'services/APIEndpoints'
import { addQueryString } from 'services/api-calls/helpers'
import { IDropdownItem } from 'types/IDropdownItem'

export const SalesPaymentStatusName: { [key: string]: string } = {
  PENDING: 'PENDING',
  AUTHORIZED: 'AUTHORIZED',
  PARTIALLY_PAID: 'PARTIALLY PAID',
  PAID: 'PAID',
  PARTIALLY_REFUNDED: 'PARTIALLY REFUNDED',
  REFUNDED: 'REFUNDED',
  VOIDED: 'VOIDED',
  UNKNOWN: 'UNKNOWN',
  CANCELLED: 'CANCELLED'
}

export const useGetPaymentsStatus = () => {
  const getPaymentsEnumFromApi = async (): Promise<any[]> => {
    return ApiRequest(addQueryString(`${API_URL}${APIEndpoints.PAYMENTS_LIST}`), 'GET')
      .then(async response => await response.json())
      .catch(e => {
        throw new Error(e)
      })
  }
  const getProcessedPaymentStatusFilter = async (): Promise<IDropdownItem[]> => {
    return (await getPaymentsEnumFromApi()).map(item => ({
      key: item.id,
      name: SalesPaymentStatusName[item.id] || item.id
    }))
  }
  return useQuery(['paymentStatusEnumsList'], getProcessedPaymentStatusFilter, {
    cacheTime: CACHE_TIME_24HOUR,
    staleTime: CACHE_TIME_24HOUR
  })
}
