import React from 'react'
import PropTypes from 'prop-types'

export const Icon = ({ description, type, ...rest }) => {
  const icons = require('@ant-design/icons')
  const Component = icons[type]
  return Component ? <Component {...rest} /> : description
}

Icon.propTypes = {
  description: PropTypes.string,
  type: PropTypes.string
}
