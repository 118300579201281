import * as React from 'react'
import PropTypes from 'prop-types'

const SvgArrowInSquare = ({ title, titleId, ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 13.5V17C20 18.6569 18.6569 20 17 20H13.5V18.5H17C17.7988 18.5 18.4518 17.8755 18.4975 17.0881L18.5 17V13.5H20ZM1.5 13.5V17C1.5 17.7988 2.12446 18.4518 2.91186 18.4975L3 18.5H6.5V20H3C1.34315 20 0 18.6569 0 17V13.5H1.5ZM15.5059 4.74765C15.5583 4.8734 15.5571 5.01509 15.5027 5.13996L10.8469 15.8118C10.7777 15.9703 10.593 16.0429 10.4341 15.9739C10.352 15.9383 10.2891 15.8693 10.2612 15.7844L9.41906 13.1355C9.22916 12.5382 9.33026 11.8864 9.69219 11.3747L11.8785 8.28353L8.47447 10.2723C7.96441 10.5703 7.34871 10.6276 6.79244 10.4287L4.20527 9.50407C4.04273 9.44428 3.95953 9.26431 4.01944 9.10209C4.04954 9.02057 4.11224 8.9552 4.19253 8.92162L14.852 4.47851C15.1069 4.37226 15.3997 4.49276 15.5059 4.74765ZM17 0C18.6569 0 20 1.34315 20 3V6.5H18.5V3C18.5 2.20116 17.8755 1.54817 17.0881 1.50255L17 1.5H13.5V0H17ZM6.5 0V1.5H3C2.20116 1.5 1.54817 2.12446 1.50255 2.91186L1.5 3V6.5H0V3C0 1.34315 1.34315 0 3 0H6.5Z"
      fill="currentColor"
    />
  </svg>
)

SvgArrowInSquare.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgArrowInSquare
