import { IMenuItems } from 'types/IMenuItem'

export const UserMenuItems: IMenuItems = {
  primaryNavbar: [
    {
      description: 'Logout',
      icon: 'ArrowRightOutlined',
      items: [],
      key: 'LOGOUT',
      order: 3,
      url: '/login',
      menuType: 'PRIMARY'
    },
    {
      description: 'Profile',
      icon: 'TeamOutlined',
      items: [],
      key: 'PROFILE',
      order: 1,
      url: '/profile',
      menuType: 'PRIMARY'
    }
  ],
  secondaryNavbar: [
    {
      description: 'VENTAS',
      icon: 'News',
      items: [],
      key: 'SALES',
      order: 1,
      url: '/sales',
      menuType: 'SECONDARY',
      roles: ['ROLE_ADMIN_MASTER']
    },
    {
      description: 'VENTAS',
      icon: 'News',
      items: [],
      key: 'SALES-LIST',
      order: 2,
      url: '/sales-list',
      menuType: 'SECONDARY',
      roles: ['ROLE_OPERATOR']
    },
    {
      description: 'PRODUCTOS',
      icon: 'News',
      items: [],
      key: 'PRODUCTOS',
      order: 3,
      url: '/products',
      menuType: 'SECONDARY',
      roles: ['ROLE_ADMIN_MASTER']
    }
  ]
}
