import * as React from 'react'
import PropTypes from 'prop-types'

const SvgClaimid = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.037 2.67H19.38a.536.536 0 0 0 .534-.532.53.53 0 0 0-.534-.531H5.037a.538.538 0 0 0-.494.328.528.528 0 0 0 .29.694c.064.026.134.04.204.04ZM19.38 7.562H5.038a.536.536 0 0 0-.535.53.53.53 0 0 0 .535.532H19.38a.536.536 0 0 0 .534-.531.53.53 0 0 0-.534-.531ZM19.38 13.295H5.038a.537.537 0 0 0-.494.328.528.528 0 0 0 .29.694c.064.027.134.04.204.04H19.38a.536.536 0 0 0 .534-.53.53.53 0 0 0-.534-.532ZM1.19 12.643a1.196 1.196 0 0 0-1.1.73 1.176 1.176 0 0 0 .259 1.29 1.193 1.193 0 0 0 2.031-.837 1.18 1.18 0 0 0-.349-.836 1.196 1.196 0 0 0-.84-.347ZM1.19 6.91a1.196 1.196 0 0 0-1.1.73A1.176 1.176 0 0 0 .35 8.929a1.193 1.193 0 0 0 2.031-.836 1.18 1.18 0 0 0-.349-.836 1.196 1.196 0 0 0-.84-.347ZM1.19.956a1.196 1.196 0 0 0-1.1.73A1.176 1.176 0 0 0 .35 2.975a1.193 1.193 0 0 0 2.031-.837 1.18 1.18 0 0 0-.349-.836 1.196 1.196 0 0 0-.84-.346"
      fill="currentColor"
    />
  </svg>
)

SvgClaimid.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgClaimid
