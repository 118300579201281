import React from 'react'
import PropTypes from 'prop-types'
import Styles from './section-simple.module.scss'
import { TitlePage } from 'common/atoms'
import { useNavigate } from 'react-router-dom'
import { CustomButton } from 'common/atoms'

export const SectionSimple = ({
  title,
  children,
  returnRoute,
  buttonExtraContentRight,
  buttonExtraContentLeft,
  type = 'default',
  buttonBackText = 'Volver',
  buttonSaveText = 'Guardar',
  onClickSave,
  saveButtonDisabled = false,
  saveButtonLoading,
  extraTopContent,
  onClickBack
}) => {
  const navigate = useNavigate()
  return (
    <div className={Styles.container}>
      <TitlePage text={title} />
      {extraTopContent}
      <div className={Styles.container__content}>{children}</div>
      <div className={Styles.container__buttonContainer}>
        {buttonExtraContentLeft}

        {type === 'default' && returnRoute && (
          <CustomButton
            onClick={() => navigate(returnRoute)}
            ghost
            type="ghost"
            className={Styles.container__buttonContainer__button}
          >
            {buttonBackText}
          </CustomButton>
        )}

        {type === 'form' && (
          <>
            <CustomButton
              onClick={() => (returnRoute ? navigate(returnRoute) : onClickBack())}
              className={Styles.container__buttonContainer__button}
            >
              {buttonBackText}
            </CustomButton>

            <CustomButton
              htmlType="submit"
              loading={saveButtonLoading}
              type="primary"
              onClick={onClickSave}
              disabled={saveButtonDisabled}
              className={Styles.container__buttonContainer__button}
            >
              {buttonSaveText}
            </CustomButton>
          </>
        )}

        {buttonExtraContentRight}
      </div>
    </div>
  )
}

SectionSimple.propTypes = {
  buttonBackText: PropTypes.string,
  buttonExtraContentLeft: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  buttonExtraContentRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  buttonSaveText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  extraTopContent: PropTypes.node,
  onClickBack: PropTypes.func,
  onClickSave: PropTypes.func,
  returnRoute: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  saveButtonLoading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['default', 'form'])
}
