import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from 'services/providers/user-context.jsx'
import { ALL_ROUTES, INVALID_URL, PERMISSIONS_ERROR, PUBLIC_ROUTES } from 'common/utils/constants'
import PropTypes from 'prop-types'
import UserHelpers from 'common/utils/user-helpers'

const getWhitelistedRoutes = items => {
  return items.reduce((previous, current) => {
    if (!current.url) return [...previous, ...getWhitelistedRoutes(current.items)]
    return [...previous, current.url]
  }, [])
}

const hasPermissions = whitelistedRoutes => {
  const baseUrl = window.location.pathname.split('/')[1]
  if (PUBLIC_ROUTES.includes(`/${baseUrl}`)) return true
  return whitelistedRoutes.includes(`/${baseUrl}`)
}

const isInvalidUrl = () => {
  const baseUrl = window.location.pathname.split('/')[1]
  return ALL_ROUTES.includes(`/${baseUrl}`)
}

export const ProtectedRoutes = ({ children }) => {
  const helper = UserHelpers()
  if (!isInvalidUrl()) return <Navigate to={INVALID_URL} replace={false} />

  // check page permissions based on user role
  if (!helper.hasRouteAccess(window.location.pathname)) return <Navigate to={PERMISSIONS_ERROR} replace={false} />

  const { user } = useContext(UserContext)
  const { menus } = user.secondaryNavbar
  const whitelistedRoutes = getWhitelistedRoutes(menus)
  return hasPermissions(whitelistedRoutes) ? children : <Navigate to={PERMISSIONS_ERROR} replace={false} />
}

ProtectedRoutes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
