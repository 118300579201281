import { Form, Input } from 'antd'
import classNames from 'classnames'
const { TextArea } = Input
import PropTypes from 'prop-types'

import Styles from './text-area-form-item.module.scss'

export const TextAreaFormItem = ({
  name,
  label,
  rules = [],
  placeholder,
  maxLength,
  formItemProps,
  formItemClassName,
  textAreaProps,
  textAreaClassName,
  autoSize
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      className={classNames(Styles.formItem, formItemClassName)}
      {...formItemProps}
    >
      <TextArea
        placeholder={placeholder}
        autoSize={autoSize}
        showCount
        maxLength={maxLength}
        className={classNames(Styles.textArea, textAreaClassName)}
        {...textAreaProps}
      />
    </Form.Item>
  )
}

TextAreaFormItem.propTypes = {
  autoSize: PropTypes.bool,
  formItemClassName: PropTypes.string,
  formItemProps: PropTypes.object,
  label: PropTypes.node,
  maxLength: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
  rules: PropTypes.array,
  textAreaClassName: PropTypes.string,
  textAreaProps: PropTypes.object
}

TextAreaFormItem.defaultProps = {
  autoSize: true
}
