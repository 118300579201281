import * as React from 'react'
import PropTypes from 'prop-types'

const SvgNotificaciones = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.797.975H3.882a.486.486 0 0 0-.484.48v4.248H.483a.485.485 0 0 0-.483.48v11.483c.001.843.339 1.652.94 2.249.6.596 1.413.932 2.262.933h10.876c.849 0 1.663-.336 2.263-.933.6-.596.939-1.405.94-2.25V1.456a.48.48 0 0 0-.484-.48Zm-.483 16.69c0 .59-.237 1.155-.656 1.571-.42.416-.988.65-1.58.651H3.202c-.592 0-1.16-.235-1.579-.652a2.216 2.216 0 0 1-.655-1.57V6.665h2.431v11.002a.48.48 0 0 0 .484.48.485.485 0 0 0 .484-.48V1.936h11.947v15.73Z"
      fill="currentColor"
    />
    <path
      d="M14.078 3.677H6.6a.486.486 0 0 0-.483.48v1.351a.479.479 0 0 0 .483.48h7.477a.485.485 0 0 0 .484-.48V4.157a.48.48 0 0 0-.484-.48Zm-.68 1.35H7.282v-.39h6.118v.39ZM9.32 7.73H6.6a.485.485 0 0 0-.482.48v2.701a.48.48 0 0 0 .483.481H9.32a.486.486 0 0 0 .484-.48V8.208a.48.48 0 0 0-.484-.48Zm-.483 2.7H7.086V8.69h1.75v1.74ZM14.078 7.73h-2.04a.485.485 0 0 0-.483.48.479.479 0 0 0 .483.48h2.04a.485.485 0 0 0 .483-.48.479.479 0 0 0-.483-.48ZM14.078 10.43h-2.04a.485.485 0 0 0-.464.48.48.48 0 0 0 .464.481h2.04a.486.486 0 0 0 .503-.48.477.477 0 0 0-.312-.45.486.486 0 0 0-.191-.03ZM14.078 13.133H6.6a.486.486 0 0 0-.465.48.48.48 0 0 0 .465.48h7.477a.486.486 0 0 0 .503-.48.477.477 0 0 0-.312-.45.488.488 0 0 0-.191-.03ZM14.078 15.834H6.6a.486.486 0 0 0-.465.48.48.48 0 0 0 .465.481h7.477a.486.486 0 0 0 .503-.48.477.477 0 0 0-.312-.45.488.488 0 0 0-.191-.03Z"
      fill="currentColor"
    />
  </svg>
)

SvgNotificaciones.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string
}

export default SvgNotificaciones
